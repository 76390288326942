import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
import { Link } from 'react-router-dom'
function App(props) {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");

  const [todo, setTodo] = useState("");
  const [todos, setTodos] = useState([])
  const [reload, setReload] = useState(false);
  const [editTodo, setEditTodo] = useState("");
  const [edittedTodo, setEdittedTodo] = useState("");
  function addTodo(e) {
    e.preventDefault();
    updateScore(1);
    if (!todo) {
      alert("Please enter a todo.")
      return;
    }
    axios.post("https://grudo-api.vercel.app/public/addItem", {
      path: 'users/' + props.user.uid + '/todos/',
      item: {
        todo: todo,
        createdAt: Date.now()
      }
    })
      .then(() => {

      })
      .catch(err => {
        console.log(err)
      })
    setTodo('');
    setReload(!reload)
  }

  function markComplete(item) {
    updateScore(1);
    axios.post("https://grudo-api.vercel.app/public/updateItem", {
      path: 'users/' + props.user.uid + '/todos/' + item,
      item: {
        complete: true,
      }
    })
      .then(() => {
        setReload(!reload)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function submitEdits(e) {
    e.preventDefault();
    axios.post("https://grudo-api.vercel.app/public/updateItem", {
      path: 'users/' + props.user.uid + '/todos/' + editTodo,
      item: {
        todo: edittedTodo,
      }
    })
    .then(() => {
      setEditTodo("");
      setEdittedTodo("")
      setReload(!reload)
    })
    .catch(err => {
      console.log(err)
    })
  }
  function markComplete(item) {
    axios.post("https://grudo-api.vercel.app/public/updateItem", {
      path: 'users/' + props.user.uid + '/todos/' + item,
      item: {
        complete: true,
      }
    })
    .then(() => {
      setReload(!reload)
    })
    .catch(err => {
      console.log(err)
    })
  }

  function updateEdits(key) {
    setEditTodo(key)
    const filteredTodos = todos.filter(todo => todo.key === key);

    setEdittedTodo(filteredTodos[0].todo)
  }

  useEffect(() => {
    axios.post("https://grudo-api.vercel.app/public/getItems", {
      path: 'users/' + props.user.uid + '/todos/'
    })
      .then(results => {
        const filteredTodos = results.data.filter(todo => !todo.complete);
        const sortedTodosDesc = filteredTodos.slice().sort((a, b) => b.createdAt - a.createdAt);

        console.log(sortedTodosDesc);
        setTodos(sortedTodosDesc);
      })
      .catch(err => {
        console.log(err);
      })
  }, [reload])
  return (
    <main>
     <Info page="grudo" />
     {
        props.user.uid ? 
        <>
       <div className="add-todo">
        <form onSubmit={(e) => addTodo(e)}>
          <input type="text" value={todo} onChange={(e) => setTodo(e.target.value)} placeholder="New todo" />
          <button onClick={(e) => addTodo(e)} style={{ marginLeft: "1rem" }}>Add</button>
        </form>
      </div>
      <div className="todos">
        {
          todos ?
            todos.map((item, index) => {
              return (
                <div key={index}>
                  {
                    item.key === editTodo ? 
                    <>
                    <input type="text" value={edittedTodo} onChange={(e) => setEdittedTodo(e.target.value)} />

                  <button onClick={(e) => submitEdits(e)}>Update</button>
                      </>
                  :
                  <>
                  <p>{item.todo}</p>
                  <button style={{margin: "0.5rem"}} onClick={() => markComplete(item.key)}>Complete</button>
                  <button onClick={() => updateEdits(item.key)}>Edit</button>
                  
                  </>
            }
                </div>
              )
            })
            :
            <p>No todos!</p>
        }
      </div>
        </>
        :
        <p>You must <Link to="/login">LOGIN</Link> in to upload.</p>
       }
    </main>
  );
}

export default App;
