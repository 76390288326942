import React, { useState, useEffect } from 'react';

import Info from '../components/Info';
import { useCustomContext } from '../state/context';
function App() {

  const { updateScore} = useCustomContext();
  const [answer, setAnswer] = useState(Math.floor(Math.random() * 100) + 1);
  const [guess, setGuess] = useState('')
  const [message, setMessage] = useState('Guess my number')

  function makeGuess(e) {
    e.preventDefault();
    if (!guess) {
      setMessage('Please make a guess')
      return;
    }
    if (guess == answer) {
      setMessage('You won! +10 points')
      updateScore(10)
    } else if (guess > answer) {
      setMessage('My number is lower. -1 point')
      updateScore(-1)
    } else if (guess < answer) {
      setMessage('My number is higher. -1 point')
      updateScore(-1)
    }
    setGuess("");
  }

  return (
    <main>
     <Info page="higher-or-lower" />
      <p>{message}</p>
      <form onSubmit={(e) => makeGuess(e)}>
      <input type="number" value={guess} onChange={(e) => setGuess(e.target.value)} placeholder="Enter your guess" />
      </form>
    </main>
  );
}

export default App;
