import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import rooms from '../constants/rooms'
import Buttons from '../components/Buttons';
import Info from '../components/Info';
import { update } from 'firebase/database';
function App() {

  const { updateScore } = useCustomContext();
  const [ room, setRoom ] = useState(1);
  const [answer, setAnswer] = useState("");
  const [answered, setAnswered] = useState(false)

  function submitAnswer(e) {
    e.preventDefault();
    if (rooms[room].answer.toLowerCase() === answer.toLowerCase()) {
      console.log("Win")
      updateScore(1);
      setAnswered(true);
      setAnswer("")
    }
    else {
      console.log(answer)
      updateScore(-1);
      console.log("Lose");
      setAnswered(false);
    }
  }

  function updateRoom(data) {
    setAnswered(false);
    setRoom(data)
  }
  return (
    <main>
     <Info page="choice" />
     <p>{rooms[room].text}</p>
      {
        rooms[room].riddle ? 
        <>
        <p>{rooms[room].riddle}</p>
        <form onSubmit={(e) => submitAnswer(e)}>
        <input type="text" value={answer} onChange={(e) => setAnswer(e.target.value)} />
        </form>
        </>
        :
        ""
      }
      {
        rooms[room].riddle && !answered ?
        ""
        :
        <div className="tunnel-buttons buttons">
        {
          rooms[room].buttons.map((item, index) => {
  return (
            <button key={index} onClick={() => updateRoom(rooms[room].buttons[index].room)}>{item.text}</button>
          )})
        }
      </div>
      }
    </main>
  );
}

export default App;
