import React, { useState, useEffect} from 'react';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useCustomContext } from '../state/context';

import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [fact, setFact] = useState("");
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(0);

  function petTheCat(num) {
    setLoading(true);
    setAnswer(num);
    setTimeout(() => {
      setLoading(false)

      if (num === 1) {
        updateScore(1)
      }
      else {
        updateScore(-1)
      }
    }, 5000)
  }
  return (
    <main>
     <Info page="pet" />
      {
        loading ? 
        <>
        <Dots />
        <p>{ answer === 1 ? "Petting..." : "You're a monster."}</p>
        </>
        :
        <>
      <p>{ answer === 0 ? "What do you do?" : answer === 1 ? "The cat purrs. What do you do?" : "A monster."}</p>
      <button onClick={() => petTheCat(1)}>Pet the cat.</button>
      <button onClick={() => petTheCat(2)}>Don't pet the cat.</button>
      </>
      }
    </main>
  );
}

export default App;
