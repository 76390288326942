import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [colors, setColors] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showStats, setShowStats] = useState(false);
  const [stats, setStats] = useState(false);
  const [allColors, setAllColors] = useState([]);
  const [colorsLength, setColorsLength] = useState(0);
  useEffect(() => {
    axios.get("https://colors-api-one.vercel.app/getTwo")
    .then(results => {
      setColors(results.data);
      setLoading(false)
    })
  }, [reload])

  useEffect(() => {
    axios.get("https://colors-api-one.vercel.app/getStats")
    .then(results => {
      let total = 0;
      for (let i = 0; i < results.data.length; i++)
      {
        if (!isNaN(results.data[i].votes)) {
        total += results.data[i].votes;
        }
      }
      setColorsLength(total);
      setAllColors(results.data);
    })
  }, [stats])

  useEffect(() => {
    const intervalId = setInterval(() => {
      playGame();
      setStats(old => !old);
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  function playGame() {
    axios.get("https://colors-api-one.vercel.app/playGame")
      .then(results => {
        setStats(old => !old);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getTextColor = (bgColor) => {
    // Use a simple algorithm to determine if the background is light or dark
    const rgb = parseInt(bgColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? '#111' : '#fff';
  };

  if (loading) return <p>Loading</p>
  return (
    <main>
     <Info page="bio" />
     <div className="colors">
     <div onClick={() => setReload(!reload)} className="left" style={{backgroundColor: colors[0].hex}}>
        <span style={{color: getTextColor}}>{colors[0].name}</span>
      </div>
      <div onClick={() => setReload(!reload)} className="right" style={{backgroundColor: colors[1].hex}}>
        <span style={{color: getTextColor}}>{colors[1].name}</span>

      </div>
      </div>
     
    </main>
  );
}

export default App;
