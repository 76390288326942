import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [suggestion, setSuggestion] = useState("");
  const [reload, setReload] = useState(false);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  useEffect(() => {
    axios.get("https://food-api-tau.vercel.app/2")
    .then(results => {
      console.log(suggestion);
      setSuggestion(results.data[0] + " and " + results.data[1] + ".")
      updateScore(1)
    })
    .catch(err => {
      console.log(err);
    })
  }, [reload])
  return (
    <main>
     <Info page="food" />
      <p>{suggestion}</p>
        <button onClick={() => setReload(old => !old)}>Reload</button>
    </main>
  );
}

export default App;
