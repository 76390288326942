const madlibs = [
  {
    "title": "Vacation Adventure",
    "template": "Once upon a time, {person} and their {adjective} {animal} went on a vacation to {destination}. They had a {adjective} time {verb} and {adverb}.",
    "wordTypes": {
      "person": "noun",
      "adjective": "adjective",
      "animal": "noun",
      "destination": "noun",
      "verb": "verb",
      "adverb": "adverb"
    }
  },
  {
    "title": "Galactic Adventure",
    "template": "In a galaxy far, far away, {hero} and their trusty {robot} embarked on a {adjective} mission to save the {planet}. Armed with a {weapon}, they faced {number} {alien}s and {adverb} {verb} their way to victory.",
    "wordTypes": {
      "hero": "noun",
      "robot": "noun",
      "adjective": "adjective",
      "planet": "noun",
      "weapon": "noun",
      "number": "number",
      "alien": "noun",
      "adverb": "adverb",
      "verb": "verb"
    }
  },
  {
    "title": "Time-Travel Mix-Up",
    "template": "One day, {name} discovered a {adjective} time machine. When they activated it, they found themselves in {historical_period}, surrounded by {plural_noun}. To get back home, they needed to find {famous_person} and ask for their {noun}.",
    "wordTypes": {
      "name": "noun",
      "adjective": "adjective",
      "historical_period": "noun",
      "plural_noun": "noun",
      "famous_person": "noun",
      "noun": "noun"
    }
  },
  {
    "title": "Secret Agent Mishap",
    "template": "Agent {codename} had a {adjective} mission to retrieve the {top_secret_item} from the {villain}'s lair. Using their {gadget}, they narrowly escaped {number} {adjective} traps and {adverb} {verb} to safety.",
    "wordTypes": {
      "codename": "noun",
      "adjective": "adjective",
      "top_secret_item": "noun",
      "villain": "noun",
      "gadget": "noun",
      "number": "number",
      "adverb": "adverb",
      "verb": "verb"
    }
  },
  {
    "title": "Culinary Chaos",
    "template": "Chef {chef_name} decided to create a {adjective} dish for the {holiday} feast. They mixed {ingredient1}, {ingredient2}, and a dash of {spice} to create a {dish_name} that left everyone {adjective} and {adjective}.",
    "wordTypes": {
      "chef_name": "noun",
      "adjective": "adjective",
      "holiday": "noun",
      "ingredient1": "noun",
      "ingredient2": "noun",
      "spice": "noun",
      "dish_name": "noun"
    }
  }
  // Add more stories as needed
]

export default madlibs;