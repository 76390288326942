import React, { useState, useEffect } from 'react';

import Info from '../components/Info';
import { useCustomContext } from '../state/context';
function App() {

  const [lower, setLower] = useState(1);
  const [higher, setHigher] = useState(101)
  const { updateScore} = useCustomContext();
  const [answer, setAnswer] = useState(Math.floor(Math.random() * 100) + 1);
  const [guess, setGuess] = useState('')
  const [message, setMessage] = useState('Pick a number from 1 - 100. Am I correct?')
  const [reload, setReload] = useState(false);
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  function low() {
    setHigher(guess);
    updateScore(1)
    setReload(old => !old);
  }

  function high() {
    setLower(guess);
    updateScore(1)
    setReload(old => !old);
  }
  useEffect(() => {
    setGuess(getRandomInt(lower, higher));
  }, [reload]);

  return (
    <main>
     <Info page="lower-or-higher" />
      <p>{message}</p>
      <p>Guess: {guess}</p>
      <button onClick={() => low()}>Lower</button>
      <button onClick={() => high()}>Higher</button> 
      <button onClick={() => setReload(old => !old)}>Reload</button>
    
      <button onClick={() => alert("Thank you!")}>Please click this button when I guess correctly. Please I don't get enough feedback and I need to know. PLEASE!</button>
     </main>
  );
}

export default App;
