import React, { useState, useEffect} from 'react';
import { getDatabase,runTransaction, ref, update, onValue } from "firebase/database";
import { useCustomContext } from '../state/context';

import Info from '../components/Info';
function App() {
const db = getDatabase();
  const { updateScore } = useCustomContext();
  const [count, setCount] = useState('');
 
  function pushTheButton() {
    // update(ref(db, 'button/'), {
    //   count: 1
    // });
    const counterRef = ref(db, 'button/count');
    runTransaction(counterRef, currentValue => {
      // If the value is null (i.e., does not exist), set it to 1
      if (currentValue === null) {
        return 1;
      }
  
      // Otherwise, increment the current value by 1
      return currentValue + 1;
    })
      .then(result => {
        // The transaction was committed successfully
        console.log('Transaction committed with new value:', result.snapshot.val());
      })
      .catch(error => {
        // Handle transaction error
        console.error('Transaction failed abnormally!', error);
      });
      updateScore(1);
  }

  useEffect(() => {
    const starCountRef = ref(db, 'button');
onValue(starCountRef, (snapshot) => {
  const data = snapshot.val();
  setCount(data.count)
});
  }, [])

  return (
    <main>
      <Info page="button" />
      
       <button onClick={() => pushTheButton()}>Hi I'm Button. Press me!</button>
       <p>Button has been clicked {count} times.</p>
    </main>
  );
}

export default App;
