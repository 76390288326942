import React, { useState } from 'react';
import { useCustomContext } from "../state/context"
import { useNavigate } from "react-router-dom";
export default function Navbar(props) {
  const { score, siteData, currentPage, updatePage } = useCustomContext();
 
  const info = siteData.filter(obj => obj.url === props.page)[0];
  


  return (
    <div className="info">
      
      <div style={{display: "flex"}}>
      <img src={require('../img/' + info.img)} alt="Page image" />
        <h2>{info.name}</h2>
      </div>
        <p>{info.spiel}</p>
    </div>
  )
}