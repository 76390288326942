import React, { useState, useEffect} from 'react';

import scrabble from '../constants/scrabble'

import Info from '../components/Info';
function App() {

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  const [words, setWords] = useState(scrabble);
  const [word, setWord] = useState([])
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  useEffect(() => {
    let shuffleWords = words;
    shuffle(shuffleWords)
    for (let i = 0; i < 1000; i++) {
      setWord(old => [...old, shuffleWords[i]])
    }
  }, [])
  function getRandomNumber() {
    // Generate a random number between 0 and 1
    const random = Math.random();
  
    // Scale and shift the random number to be between 0.8 and 2
    const randomNumber = random * (2 - 0.8) + 0.8;
  
    return randomNumber + "rem";
  }
  return (
    <main>
      <Info page="words" />
     <p className="center">{
      word.map((item, index) => {
        return(
          <span 
          style={{
            fontFamily: getRandomInt(0,4) === 0 ? "Lato" : getRandomInt(0,4) === 0 ? "Merriweather" : getRandomInt(0,4) === 0 ? "Gerogia" : "Helvetica",
            fontWeight: getRandomInt(0, 10) === 0 ? "700" : getRandomInt(0, 8) === 0 ? "300" : "400",
            fontSize: getRandomNumber(),
            fontStyle: getRandomInt(0, 8) === 0 ? "italic" : "normal"
          }}
          key={item}>{item} </span>
        )
      })
}</p>
    </main>
  );
}

export default App;
