// utils.js

export const getCroppedImg = (image, crop, zoom) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX * zoom,
    crop.height * scaleY * zoom,
    0,
    0,
    crop.width,
    crop.height
  );

  return canvas;
};

export const applyGrayscaleFilter = (imageDataURL) => {
  const img = new Image();
  img.src = imageDataURL;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = img.width;
  canvas.height = img.height;

  ctx.drawImage(img, 0, 0);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    const brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
    data[i] = brightness;
    data[i + 1] = brightness;
    data[i + 2] = brightness;
  }

  ctx.putImageData(imageData, 0, 0);

  return canvas.toDataURL('image/png');
};
