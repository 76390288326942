const words = {
  4: [
    'book',
    'cake',
    'door',
    'fish',
    'golf',
    'hike',
    'jump',
    'kite',
    'lamp',
    'moon'
  ],
  5: [
    'apple',
    'beach',
    'cloud',
    'daisy',
    'earth',
    'fairy',
    'grape',
    'happy',
    'juice',
    'kite'
  ],
  6: [
    'banana',
    'cookie',
    'dragon',
    'eleven',
    'flower',
    'guitar',
    'humble',
    'island',
    'jigsaw',
    'kettle'
  ],
  7: [
    'awesome',
    'chimney',
    'freedom',
    'glacier',
    'journey',
    'maximum',
    'plumber',
    'quicken',
    'sixteen',
    'victory'
  ],
  8:  [
    
  'calendar',
  'daughter',
  'friendly',
  'language',
  'marathon',
  'nautical',
  'positive',
  'rainbow',
  'terrible',
  'whisper'
  ],
  9: [
  'attention',
  'beautiful',
  'celebrate',
  'direction',
  'elevation',
  'furniture',
  'happiness',
  'incredible',
  'jewellery',
  'knowledge'
  ],
  10: [
    'apartment',
  'butterfly',
  'chocolate',
  'definitely',
  'excellent',
  'happiness',
  'juxtaposed',
  'knowledge',
  'magnificent',
  'necessarily'
  ]
}

export default words;