import React, { useState, useEffect} from 'react';

import Info from '../components/Info';

import { useCustomContext } from '../state/context';
function App() {

  const { updateScore } = useCustomContext();
  const [word, setWord] = useState(''); // the word that shows up
  function RenderKeyboard() { // create the keyboard
    const alphabet = 'qwertyuiopasdfghjklzxcvbnm_<';

    return Array.from(alphabet).map((letter, index) => ( // loop through the alphabet and make buttons
      <>
      <button
      style={{ margin: "0.25rem"}}
        key={index}
        onClick={() => handleKeyboard(letter)}
      >
        {letter}
      </button>
      {
        letter === "p" || letter === "l" ?
        <br />
        :
        ""
      }
      </>
    ));
  };

  function handleKeyboard(letter) { // handle key presses
    // add the appropriate character
    if (letter === "_") { // space
      setWord(word + " ");
      updateScore(1);
    } else if (letter === "<") { // backspace
      setWord(word.slice(0, -1));
      updateScore(-1);
    } else { // everything else
      setWord(word + letter);
      updateScore(1);
    }
  }
  
  return (
    <main>
      <Info page="keyboard" />
      
      <p>{word}</p>
      <div className="keyboard">
        <RenderKeyboard />
      </div>
    </main>
  );
}

export default App;
