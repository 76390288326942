const rocks = [
  {"name": "Talc", "hardness": 1},
  {"name": "Gypsum", "hardness": 2},
  {"name": "Calcite", "hardness": 3},
  {"name": "Fluorite", "hardness": 4},
  {"name": "Apatite", "hardness": 5},
  {"name": "Orthoclase Feldspar", "hardness": 6},
  {"name": "Quartz", "hardness": 7},
  {"name": "Topaz", "hardness": 8},
  {"name": "Corundum", "hardness": 9},
  {"name": "Diamond", "hardness": 10},
  {"name": "Dolomite", "hardness": 3.5},
  {"name": "Jadeite", "hardness": 6.5},
  {"name": "Beryl", "hardness": 7.5},
  {"name": "Hematite", "hardness": 5.5},
];
export default rocks;