import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import data from '../constants/rocks';
import Info from '../components/Info';
function App() {
  const [rocks, setRocks] = useState(data);
  const { updateScore } = useCustomContext();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  function answer(win, lose) {
    if (questions[currentQuestion][win].hardness > questions[currentQuestion][lose].hardness) {
      setScore(old => old + 1)
    }
    setCurrentQuestion(old => old + 1);
  }

  function replay()
  {
    setCurrentQuestion(0);
    setScore(0);
  }

  useEffect(() => {
    let newRocks = [...rocks];
    shuffle(newRocks);
    let toAdd = [];
    for (let i = 0; i < newRocks.length; i++)
    {
      let adding = [newRocks[i], newRocks[i+1]];
      toAdd.push(adding);
      i++
    }
    setQuestions(toAdd);
    setLoading(false);
  },[])

  if (loading) { return <p>Loading</p>}
  return (
    <main>
     <Info page="rock" />
     <p>Score: {score}</p>
     {
      currentQuestion < 7 ?
      <>
      <h3 style={{marginBottom: "2rem"}}>Question #{currentQuestion+1}</h3>
     <p>Which rock is harder?</p>
     {
      questions &&
       <button onClick={() => answer(0,1)}>{questions[currentQuestion][0].name}</button>
     }
     <p>Or</p>
     {
      questions &&
       <button onClick={() => answer(1,0)}>{questions[currentQuestion][1].name}</button>
     }
     </> : 
     <>
     <p>Here are the answers:</p>
     <ul>
      {
        rocks.map((item, index) => {
          return(
            <li key={index}>{item.name} - {item.hardness}</li>
          )
        })
      }
     </ul>
     <button onClick={() => replay()}>Replay</button>
     </>
}

    </main>
  );
}

export default App;
