import React, { useState, useEffect } from 'react';
import Info from "../components/Info";

import { useCustomContext } from '../state/context';
const phrases = ['apartment',
'butterfly',
'chocolate',
'definitely',
'excellent',
'happiness',
'juxtaposed',
'knowledge',
'magnificent',
'necessarily'];


const HangmanGame = () => {

  const { updateScore } = useCustomContext();
  const [selectedPhrase, setSelectedPhrase] = useState('');
  const [displayPhrase, setDisplayPhrase] = useState('');
  const [guess, setGuess] = useState('');
  const [gameIndex, setGameIndex] = useState(0);
  const [positions, setPositions] = useState([true, false, false, false, false, false, false]);
  const [incorrectGuesses, setIncorrectGuesses] = useState([]);
  const maxAttempts = 6;

  useEffect(() => {
    // Choose a random phrase from the list
    const randomIndex = Math.floor(Math.random() * phrases.length);
    setSelectedPhrase(phrases[randomIndex]);

    // Initialize the display phrase with underscores
    setDisplayPhrase('_'.repeat(phrases[randomIndex].length));
  }, []);

  const handleGuess = () => {
    if (guess.length !== 1 || !/^[a-zA-Z]+$/.test(guess)) {
      alert('Please enter a single letter.');
      return;
    }

    if (selectedPhrase.includes(guess)) {
      const newDisplayPhrase = selectedPhrase
        .split('')
        .map((char, index) =>
          char === guess || displayPhrase.charAt(index) === char ? char : '_'
        )
        .join('');
      setDisplayPhrase(newDisplayPhrase);
      updateScore(1);
    } else {
      updateScore(-1)
      setIncorrectGuesses([...incorrectGuesses, guess]);
      if (gameIndex < 6) {
        setGameIndex(old => old + 1)
      }
      else
      {
        console.log("Game over")
      }
    }

    setGuess('');
  };

  const isGameOver = () => {
    if (displayPhrase === selectedPhrase) return 'win';
    if (incorrectGuesses.length >= maxAttempts) return 'lose';
    return 'playing';
  };

  const gameStatus = isGameOver();

  return (
    <main>
      <Info page="cheese-cat" />
      <div className="cheeseCat">
        {
          positions.map((item, index) => {
            if (index === 6) {
              return(
                <img src={require('../img/cat.png')} />
              )
            }
            else {
              if (index === gameIndex) {
                return(
                  <img src={require('../img/cheese.png')} />
                )
              }
              else {
                return(<div></div>)
              }
            }
          })
        }
      </div>
      <div className="hangman">
        {gameStatus !== 'playing' && (
          <div className="result">
            {gameStatus === 'win' ? 'You win!' : 'You lose!'}
          </div>
        )}
        <div className="word" style={{width: "100%", textAlign: "center", fontSize: "1.2rem", marginBottom: "1rem"}}>
          {selectedPhrase.split('').map((char, index) => (
            <span key={index}>{displayPhrase.charAt(index)}</span>
          ))}
        </div>
        {gameStatus === 'playing' && (
          <div>
            <p style={{textAlign: "center"}}>Guesses: {incorrectGuesses.join(', ')}</p>
            <input
              type="text"
              maxLength="1"
              value={guess}
              onChange={(e) => setGuess(e.target.value)}
            />
            <button onClick={handleGuess}>Guess</button>
          </div>
        )}
      </div>
    </main>
  );
};

export default HangmanGame;
