import React, { useState, useEffect} from 'react';

import axios from 'axios'
import Info from '../components/Info';

import { useCustomContext } from '../state/context';
function App() {

  const { updateScore } = useCustomContext();
  const [dice, setDice] = useState([]);
  const [numDice, setNumDice] = useState(1)
  const [value, setValue] = useState(6)
  const [reload, setReload] = useState(false);
  const [answer, setAnswer] = useState(0);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  function roll() {
    setAnswer("")
    let num = 0;
    let max = parseInt(value) + 1;
    for (let i = 0; i < numDice; i++) {
      num += getRandomInt(1, max)
    }
    setAnswer(num);
    updateScore(1)
  }

  return (
    <main className="main-center">
     <Info page="dice" />
      <p>Value: {answer}</p>
          <label>Number of dice</label>
            <input type="number" value={numDice} onChange={(e) => setNumDice(e.target.value)} />
          <label>Size of dice</label>
            <input type="number" value={value} onChange={(e) => setValue(e.target.value)} />
      <button onClick={() => roll()}>Roll</button>
    </main>
  );
}

export default App;
