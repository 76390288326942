import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Spinner } from 'react-bootstrap';
import Info from '../components/Info';
import { useCustomContext } from '../state/context';
import Modal from 'react-bootstrap/Modal';
const api = "http://localhost:3000";
function App() {

  const { updateScore } = useCustomContext()
  const [totalMatches, setTotalMatches] = useState('');
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true)
  const [showTeam, setShowTeam] = useState(false);
  const [team, setTeam] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await axios.get(api + "/teams/getTeams");
        const data = results.data;
        let wins = 0;
        for (let i = 0; i < data.length; i++) {
          wins += data[i].wins
        }
        setTotalMatches(wins)
        const sorted = sortByPropertyDescending(data, "elo");
        setTeams(sorted)
        playGame();
        setLoading(false);

      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 1000 milliseconds
    const intervalId = setInterval(fetchData, 100);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateScore(1)
    }, 5000);

  }, [])

  function playGame() {
    axios.get(api + "/games/playGame")
      .then(result => {
        console.log(result.data)
        console.log("Success")
      })
      .catch(err => {
        console.log(err);
        console.log("Error")
      })
  }
  function addTeam() {
    axios.get(api + "/teams/addTeam")
      .then(result => {
        console.log(result.data)

      })
      .catch(err => {
        console.log(err);
      })
  }

  function openModal(obj) {
    setTeam(obj);
    console.log(obj)
    setShowTeam(true);
  }
  function sortByPropertyDescending(array, property) {
    return array.slice().sort((a, b) => {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
      return 0;
    });
  }
  return (
    <main>
      {
        loading ?
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spinner size="lg" />
          </div>
          :
          <>
            <Info page="grucha-libre" />
            <p>Total battles: {totalMatches}</p>
            <button onClick={() => addTeam()}>Add Team</button>
            {/* <button onClick={() => playGame()}>Play Team</button> */}
            <Table>

              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Elo</th>
                </tr>
              </thead>
              <tbody>
                {
                  teams ? teams.map((item, index) => {
                    return (
                      <>
                        <tr key={index} onClick={() => openModal(item.players)}>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.wins}</td>
                          <td>{item.losses}</td>
                          <td>{item.elo}</td>
                        </tr>

                      </>

                    )
                  })
                    :
                    ""
                }
              </tbody>
            </Table>
          </>
      }
      <Modal show={showTeam} onHide={() => setShowTeam(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>

            <thead>
              <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Hits</th>
                <th>Walks</th>
                <th>HR</th>
                <th>Avg</th>
              </tr>
            </thead>
            <tbody>
              {
                Object.keys(team).map((key, jindex) => {
                  return (

                    <tr key={key}>
                      <td>{team[key].name}</td>
                      <td>{team[key].pos}</td>
                      <td>{team[key].hits}</td>
                      <td>{team[key].walks}</td>
                      <td>{team[key].hr}</td>
                      <td>{team[key].avg}</td>
                    </tr>
                  )
                })
              }

            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button variant="secondary" onClick={() => setShowTeam(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </main>
  );
}

export default App;
