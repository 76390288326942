import React, { useState, useEffect } from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import { Table } from 'react-bootstrap';
import Info from '../components/Info';

import contestantdata from '../constants/contestants'
function App() {
  
  const { updateScore } = useCustomContext();
  const [game, setGame] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    // setContestants(old => shuffle(old))
    axios.get("https://taskmaster-api.vercel.app/getStats")
      .then(results => {
        setStats(results.data)
        console.log(results.data);
      })
      .catch(err => {
        console.log(err);
      })
  }, [reload])

  useEffect(() => {
    setLoading(true);
    axios.get("https://taskmaster-api.vercel.app/getTwo")
      .then(results => {
        setGame(results.data)
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      })
  }, [reload])

  function playGame(winner, loser) {
    setLoading(true);
    axios.post("https://taskmaster-api.vercel.app/playGame", {
      winner: winner,
      loser: loser
    })
      .then(() => {
        updateScore(1);
        setReload(old => !old)
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      })
  }
  return (
    <main>
      <Info page="taskmaster" />
      <div className="taskmaster">
        {
          loading ? 
          <p>Loading...</p>
          :
        <>
        <div onClick={() => playGame(game[0], game[1])}>
          <img src={require('../img/taskmaster/' + game[0].img)} alt={game[0].name} />
          <div>
          <h3>{game[0].name}</h3>
          <p>Series {game[0].series}</p>
          </div>
        </div>
        <div onClick={() => playGame(game[0], game[1])}>
          <img src={require('../img/taskmaster/' + game[1].img)} alt={game[1].name} />
          <div>
          <h3>{game[1].name}</h3>
          <p>Series {game[1].series}</p>
          </div>
        </div>
        </>
}
      </div>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Wins</th>
            <th>Elo</th>
          </tr>
        </thead>
        <tbody>
          {
            stats &&
            stats.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <th>{item.votes ? item.votes : 0}</th>
                  <td>{item.elo}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </main>
  );
}

export default App;
