import React, { useState, useEffect } from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [stage, setStage] = useState(0);
  const [numbers, setNumbers] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [answer, setAnswer] = useState('');
  const [index, setIndex] = useState(0);
  const [amount, setAmount] = useState(3);

  function startGame() {
    setIndex(0);
    setNumbers(old => shuffle(old));
    setStage(1);
    timer();
  }

  function timer() {
    if (index < amount) {
      setTimeout(() => {
        setIndex(old => old + 1);
        timer();
      }, 1000)
    }
  }

  useEffect(() => {
    if (index === amount) {
      setStage(2);
    }
  }, [index])

  function submit(e) {
    e.preventDefault();
    if (!answer) {
      alert("Please write an answer.");
      return;
    }
    let correct = numbers.slice(); // Create a copy of 'numbers'
    correct = correct.splice(0, amount);

    const concatenatedNumber = parseInt(correct.join(''), 10);
    if (answer == concatenatedNumber) {
      alert("You won!")
      if (amount < numbers.length) {
        setAmount(old => amount+1);
      }
      setStage(0)
    }
    else {
      alert("Sorry but no.")
    }
    setAnswer('');
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
  return (
    <main>
      <Info page="numbers" />
      <div style={{ marginTop: "2rem" }}>
        {
          stage === 0 ?
            <button onClick={() => startGame()}>Start game</button>
            : stage === 1 ?
              <p style={{ fontSize: "5rem" }}>{numbers[index]}</p>
              : stage === 2 ?
                <>
                  <form onSubmit={(e) => submit(e)}>
                    <input type="number" value={answer} onChange={(e) => { setAnswer(e.target.value) }} />

                    <button onClick={(e) => submit(e)}>Submit answer</button>
                  </form>
                  <button onClick={() => startGame()}>Reset</button>
                </>
                : ""
        }
      </div>
    </main>
  );
}

export default App;
