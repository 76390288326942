import React, { useState, useEffect } from 'react';

import Info from '../components/Info';
import axios from 'axios';

import { useCustomContext } from '../state/context';
function App() {

    const { updateScore } = useCustomContext();
  const [url, setURL] = useState("");
  const [short, setShort] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    axios.post('https://url-shortener-gamma-two.vercel.app', {
      url: url
    })
    .then(results => {
      updateScore(1);
      setShort(results.data.url)
    })
    .catch(err => {
      console.log(err)
    })
  }

  function updateURL(e) {
    setShort("")
    setURL(e)
  }

  return (
   <main>
    <Info page="url" />  
      <form onSubmit={(e) => handleSubmit(e)}>
        <input type="text" value={short ? short : url} onChange={(e) => updateURL(e.target.value)} placeholder="Enter URL" />
        <button onClick={(e) => handleSubmit(e)}>Submit</button>
      </form>
    </main>
  );
}

export default App;
