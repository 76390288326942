import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';

import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [fact, setFact] = useState("Cow fact incoming!");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    axios.get("https://cows-gruman.vercel.app")
    .then(results => {
      setFact(results.data)
      updateScore(1)
    })
    .catch(err => {
      console.log(err);
    })
  }, [reload])
  return (
    <main>
     <Info page="cow" />
      <p className="center">Cow fact:</p>
      <p className="center">{fact}</p>
      <button onClick={() => setReload(old => !old)}>Reload</button>
    </main>
  );
}

export default App;
