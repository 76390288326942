import React, { useState, useEffect, useRef} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [squares, setSquares] = useState(Array.from({ length: 100 }, () => false));
  const [reload, setReload] = useState(false);
  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);
  useEffect(() => {
    const updateWidth = () => {
      if (divRef.current) {
        setDivWidth(divRef.current.clientWidth);
      }
    };

    // Call the function once to set the initial width
    updateWidth();

    // Add a resize event listener to update the width when the window is resized
    window.addEventListener('resize', updateWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  const handleClick = (index) => {
    setSquares((prevSquares) => {
      const newSquares = [...prevSquares];
      newSquares[index] = !newSquares[index]; // Set the clicked square to true
      return newSquares;
    });
  };

  return (
    <main ref={divRef}>
     <Info page="draw" />
     <div className="draw-container">
      {
        squares.map((item, index)=> {
          return(
            <div
            key={index}
            onClick={() => handleClick(index)}
            style={{ backgroundColor: item ? '#111' : '#fff', width: divWidth/10, height: divWidth/10 }}
          >
              </div>
          )
        })
      }
     </div>
      </main>
  );
}

export default App;
