import React, { useState, useEffect} from 'react';

import axios from 'axios'
import data from '../constants/merriam'

import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const wordsdata = Object.keys(data);

  const { updateScore } = useCustomContext();
  const [smell, setSmell] = useState("");
  const [reload, setReload] = useState(false);

  const timer = 30
  const [definition, setDefinition] = useState('')

  const [wordsUsed, setWordsUsed] = useState([
    'kale',
    'glass',
    'mug',
    'sunglasses'
  ])
  const [scores, setScores] = useState([])
  const [words, setWords] = useState([])
  const [word, setWord] = useState('')
  const [currentWord, setCurrentWord] = useState(0)
  const [numWords, setNumWords] = useState(10)
  const [loading, setLoading] = useState(true)
  const [score, setScore] = useState(0)
  const [seconds, setSeconds] = useState(timer)
  const [gameStarted, setGameStarted] = useState(false)
  const [gameOver, setGameOver] = useState(false)
  function shuffle (array) {
    let currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex]
      ]
    }

    return array
  }

  const fetchDefinition = async word => {
    try {
      const response = await axios.get(
        `https://www.dictionaryapi.com/api/v3/references/collegiate/json/${word}?key=ccf013a9-9707-4209-b097-bbb7c663dc90`
      )
      const definition = response.data[0]?.shortdef?.[0]
      return definition
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const Definition = ({ word }) => {
    const [definition, setDefinition] = useState(null)

    useEffect(() => {
      const fetchWordDefinition = async () => {
        const fetchedDefinition = await fetchDefinition(word)
        setDefinition(fetchedDefinition)
      }

      fetchWordDefinition()
    }, [word])

    return (
      <div className='definition'>
        <h2>Word: {word}</h2>
        {definition && <p>Definition: {definition}</p>}
      </div>
    )
  }
  useEffect(() => {
    shuffle(wordsdata)
    setWords(wordsdata)
    const storedScores = JSON.parse(localStorage.getItem('hiScores')) || []
    setScores(storedScores)
    setLoading(false)
  }, [])

  useEffect(() => {
    localStorage.setItem('hiScores', JSON.stringify(scores))
  }, [scores])

  function restart () {
    setLoading(true)
    setGameStarted(true)
    setWord('')
    setCurrentWord(0)
    shuffle(wordsdata)
    setWords(wordsdata)

    setScore(0)
    setLoading(false)
  }

  useEffect(() => {
    setWordsUsed(old => [...old, words[currentWord]])
  }, [currentWord])

  useEffect(() => {
    if (
      currentWord < words.length &&
      word.toLowerCase() === words[currentWord].toLowerCase()
    ) {
      setCurrentWord(old => old + 1)
      setScore(old => old + word.length)
      setScores(old => [...old, score])
      setWord('')
    }
  }, [word, currentWord, words])

  useEffect(() => {
    if (seconds <= 0) {
      setGameOver(true)
      setGameStarted(false)
      setSeconds(timer)
      if (!score) return

      const newScores = [...scores, parseInt(score)]
      newScores.sort((a, b) => b - a)
      const topFiveScores = newScores.slice(0, 5)
      setScores(topFiveScores)
    }
  }, [seconds])

  useEffect(() => {
    if (seconds > 0 && gameStarted) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [seconds, gameStarted])

  if (loading) {
    return <p>Loading</p>
  }
  return (
    <main>
     <Info page="typist" />
     {gameStarted ? (
        <>
          <p className='timer'>{seconds}</p>
          <p className='word'>
            {words[currentWord].split('').map((char, index) => (
              <span
                key={index}
                style={{
                  color:
                    word.split('')[index] === char
                      ? 'green'
                      : word.split('')[index] !== char && word.split('')[index]
                      ? 'red'
                      : ''
                }}
              >
                {char}
              </span>
            ))}
          </p>
          <input
            spellCheck='false'
            autoFocus
            type='text'
            value={word}
            placeholder='Enter word'
            onChange={e => setWord(e.target.value)}
          />

          <p className='score'>Score: {score}</p>
        </>
      ) : gameOver ? (
        <>
          <p className='results'>You typed {score} correct letters!</p>

          <button onClick={() => restart()}>Replay</button>
          {/* <h2>Definitions</h2>
          {
            <div className='definitions'>
              {wordsUsed.map((word, index) => {
                if (word)
                {
                return(
                <Definition key={index} word={word} />
                )
                }
                })}
            </div>
          } */}
          <h2>Hi Scores</h2>
          <ul>
            {scores.map((score, index) => (
              <li key={index}>{score}</li>
            ))}
          </ul>
        </>
      ) : (
        <>
          {/* <Definition word='volume' /> */}
          <p>You will be presented with a word. Please type it. It will automatically go to the next word, you don't have to press Enter. Have fun!</p>
          <button onClick={() => setGameStarted(true)}>Start Game</button>
        </>
      )}
    </main>
  );
}

export default App;
