import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import data from '../constants/scrabble';

import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [words, setWords] = useState(data);
  const [answers, setAnswers] = useState([]);
  const [word, setWord] = useState('')
  const [reload, setReload] = useState(false);
  const [definition, setDefinition] = useState("")
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  function makeChoice(choice) {
    if (choice === word) {
      alert("You won! Try again.")
      updateScore(1);
      setReload(old => !reload)
    }
    else
    {
      updateScore(-1);
      alert('Nope! Try again.')
    }
  }

  useEffect(() => {
    shuffle(words);
    setWord(words[0]);
    let temp = [words[0], words[1], words[2]];
    shuffle(temp);
    setAnswers(temp);

    axios.get("https://www.dictionaryapi.com/api/v3/references/collegiate/json/" + words[0] + "?key=ccf013a9-9707-4209-b097-bbb7c663dc90")
    .then(results => {
      if (results.data[0].shortdef) {
      setDefinition(results.data[0].shortdef);
      }
      else
      {
        setDefinition("No definition found.")
      }
    })
    .catch(err => {
      console.log(err);
    })
  }, [reload])
  return (
    <main>
     <Info page="definitions" /> 
      <p className="center">Definition:</p>
      <p className="center">{definition}</p>
        {
          answers.map((item, index) => {
            return(
              <button onClick={() => makeChoice(item)}>{item}</button>
            )
          })
        }
    </main>
  );
}

export default App;
