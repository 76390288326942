import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [clicks, setClicks] = useState(0);
  const [upgrades, setUpgrades] = useState(0);
  const [speeds, setSpeeds] = useState(0);
  const [clickMultiplier, setClickMultiplier] = useState(1);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [speed, setSpeed] = useState(5000);
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleButtonClick();
    },speed / speedMultiplier)
    return () => {
      clearInterval(intervalId);
    };
  })

  useEffect(() => {
    // This effect will run whenever the upgrades state changes
    // You can perform additional logic here based on upgrades
    // For simplicity, let's just update clickMultiplier based on upgrades
    setClickMultiplier(1 + upgrades * 0.1);
  }, [upgrades]);

  useEffect(() => {
    // This effect will run whenever the upgrades state changes
    // You can perform additional logic here based on upgrades
    // For simplicity, let's just update clickMultiplier based on upgrades
    setSpeedMultiplier(1 + speeds * 0.1);
  }, [speeds]);


  const handleButtonClick = () => {
    // Increase clicks based on the current clickMultiplier
    setClicks(clicks + clickMultiplier);
  };

  const handleSpeedClick = () => {
    const upgradeCost = 10 * Math.pow(2, speeds); // Upgrade cost formula (adjust as needed)
    if (clicks >= upgradeCost) {
      // Deduct clicks and increment upgrades
      setClicks(clicks - upgradeCost);
      setSpeeds(speeds + 1);
    } else {
      alert("Not enough clicks to purchase the speed upgrade!");
    }
  };

  const handleUpgradeClick = () => {
    // Deduct a certain number of clicks to purchase an upgrade
    const upgradeCost = 10 * Math.pow(2, upgrades); // Upgrade cost formula (adjust as needed)
    
    if (clicks >= upgradeCost) {
      // Deduct clicks and increment upgrades
      setClicks(clicks - upgradeCost);
      setUpgrades(upgrades + 1);
    } else {
      alert("Not enough clicks to purchase the upgrade!");
    }
  };
  return (
    <main>
     <Info page="bio" />
     <p>Clicks: {clicks.toFixed(2)}</p>
      <button onClick={handleButtonClick}>Click Me!</button>
      <p>Upgrade Cost: {10 * Math.pow(2, upgrades).toFixed(2)} Clicks</p>
      <button onClick={handleUpgradeClick}>Purchase Upgrade</button>
      <p>Click Multiplier: {clickMultiplier.toFixed(2)}</p>
      <p>Speed upgrade Cost: {10 * Math.pow(2, speeds).toFixed(2)} Clicks</p>
      <button onClick={handleSpeedClick}>Speed Upgrade</button>
      <p>Speed Multiplier: {speedMultiplier.toFixed(2)}</p>
      </main>
  );
}

export default App;
