const battlers = [
  {
    name: "Bruiser",
    hp: 100,
    currentHP: 100,
    str: 10,
    atk: 5,
    def: 8,
    spd: 2
  },{
    name: "Brody",
    hp: 90,
    currentHP: 90,
    str: 5,
    atk: 10,
    def: 4,
    spd: 9
  }
]

export default battlers;