import React, { useState, useEffect} from 'react';

import stories from '../constants/madlibs';
import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [words, setWords] = useState({});
  const [selectedStory, setSelectedStory] = useState(null);
  const [stage, setStage] = useState(0);
  const [show, setShow] = useState(false);

  const handleInputChange = (type, e) => {
    const value = e.target.value;
    setWords((prevWords) => ({ ...prevWords, [type]: value }));
  };

  const handleStorySelection = (storyIndex) => {
    setSelectedStory(storyIndex);
    setWords({});
  };

  const generateMadLibs = () => {
    if (selectedStory !== null) {
      const story = stories[selectedStory];
      let madLibsStory = story.template;

      Object.keys(words).forEach((type) => {
        madLibsStory = madLibsStory.replace(`{${type}}`, words[type]);
      });

      return madLibsStory;
    }
    return 'Please select a story.';
  };

  return (
    <main className="main-left">
     <Info page="madlibs" />
       <>
      <h4>Stage 1: choose your story</h4>
        <ul className="stories">
          {stories.map((story, index) => (
            <li key={index} onClick={() => handleStorySelection(index)}>
              {story.title}
            </li>
          ))}
        </ul>
        </>
     <div style={{marginBottom: '1rem'}}>
      <h4>Stage 2: enter your words</h4>
        {selectedStory !== null && (
          <form>
            {Object.keys(stories[selectedStory].wordTypes).map((type) => (
              <div key={type}>
                <label>{type}:</label>
                <input
                  type="text"
                  value={words[type] || ''}
                  onChange={(e) => handleInputChange(type, e)}
                />
              </div>
            ))}
          </form>
        )}
      </div>
 
      <div>

      <h4>Stage 3: enjoy your story</h4>
        <h3>{stories[selectedStory].title}</h3>
        <p>{generateMadLibs()}</p>
      </div>

      </main>
  );
}

export default App;
