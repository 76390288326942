import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  
  const [colors, setColors] = useState([]);
  const [show, setShow] = useState([true, true, true, true, true, true, true, true]);
  const [count, setCount] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const [clicks, setClicks] = useState(0);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  function getRandomColor() {
    let r = getRandomInt(0, 256);
    let g = getRandomInt(0, 256);
    let b = getRandomInt(0, 256);
    return {
      r: r,
      g: b,
      b: b
    }
  }

  useEffect(() => {
    if (firstTime) {


      for (let i = 0; i < 8; i++) {
        //  temp.push("rgba(0, 0, 0, 1");
        setColors(old => [...old, getRandomColor()])
      }
      setFirstTime(false)
    }

    // console.log(temp);
    // setColors(temp);
  }, [])

  function breed() {
    let newColors = [];
    let newColor;
    let r;
    let b;
    let g;

    for (let i = 0; i < 6; i++) {
      if (i != 5) {
        if (getRandomInt(0, 10) < 5) {
          r = colors[count[i]].r;
        }
        else {
          r = colors[count[i + 1]].r;
        }
        if (getRandomInt(0, 10) < 5) {
          g = colors[count[i]].g;
        }
        else {
          g = colors[count[i + 1]].g;
        }
        if (getRandomInt(0, 10) < 5) {
          b = colors[count[i]].b;
        }
        else {
          b = colors[count[i + 1]].b;
        }
      }
      else {
        if (getRandomInt(0, 10) < 8) {
          r = colors[count[i]].r;
        }
        else {
          r = colors[count[0]].r;
        }
        if (getRandomInt(0, 10) < 8) {
          g = colors[count[i]].g;
        }
        else {
          g = colors[count[0]].g;
        }
        if (getRandomInt(0, 10) < 8) {
          b = colors[count[i]].b;
        }
        else {
          b = colors[count[0]].b;
        }

      }
      newColor = {
        r: r,
        g: g,
        b: b
      }
      newColors.push(newColor);
    }

      for (let i = 0; i < 2; i++) {
        newColors.push(getRandomColor());
      }
      setColors(newColors);
      console.log(newColors)
      setShow([true, true, true, true, true, true, true, true]);
    }

    function handleClick(id) {
      let temp = [...show];
      temp[id] = false;
      setShow(temp);
      setCount(old => [...old, id]);
      setClicks(clicks + 1);
      if (clicks === 7) {
        setClicks(0)
        breed();
      }
    }


    return (
      <main>
        <Info page="ai" />
        <div className="colors-container">
          {
            colors.map((item, index) => {
              return (
                <div onClick={() => handleClick(index)} key={index} style={{ backgroundColor: show[index] ? "rgba(" + colors[index].r + "," + colors[index].g + "," + colors[index].b + ")" : "#fff" }}>
                </div>
              )
            })
          }
        </div>
      </main>
    );
  
}

export default App;
