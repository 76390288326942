import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");
  const [reload, setReload] = useState(false);

  const [typedWord, setTypedWord] = useState('');
  // State to track login status
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Event listener to handle keydown events
    const handleKeyDown = (event) => {
      const key = event.key.toLowerCase();
      // Exclude the 'meta' key from the typed word
      if (key !== 'meta') {
        setTypedWord((prevTypedWord) => prevTypedWord + key);
      }
    };

    // Function to check for login based on typed word
    const checkLogin = () => {
      if (typedWord === 'the password') {
        setLoggedIn(true);
        setTypedWord('');
      }
    };

    // Add event listener when component mounts
    window.addEventListener('keydown', handleKeyDown);
    // Check for login on initial render
    checkLogin();

    // Cleanup: remove event listener when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [typedWord]);
  return (
    <main>
     <Info page="secret" />
      {
        loggedIn ?
        <div><ul>
        <li>The moon is not made of green cheese.</li>
        <li>Cats can understand human emotions.</li>
        <li>Pineapples don't belong on pizza.</li>
        <li>Water is wet.</li>
        <li>The Earth is round.</li>
        <li>Chocolate makes everything better.</li>
        <li>The dress is blue and black, not white and gold.</li>
        <li>Bananas are berries, but strawberries are not.</li>
        <li>Penguins are birds that can't fly.</li>
        <li>The secret to happiness is a good cup of coffee.</li>
        <li>Time travel hasn't been invented yet.</li>
        <li>Birds are descendants of dinosaurs.</li>
        <li>Honey never spoils.</li>
        <li>The Great Wall of China is not visible from space.</li>
        <li>Bees can recognize human faces.</li>
        <li>The shortest war in history lasted 38-45 minutes.</li>
        <li>The Eiffel Tower can be 15 cm taller during the summer.</li>
        <li>The average person falls asleep in seven minutes.</li>
        <li>Cows have best friends.</li>
        <li>The fortune cookie originated in Japan, not China.</li>
      </ul>
      </div>
        :
        <div>
          <p>Use the password to unlock secrets.</p>
          </div>
      }
        </main>
  );
}

export default App;
