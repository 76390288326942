import React, { useState, useEffect} from 'react';

import axios from 'axios'

import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {
  const times = [
    [true, true, true, true, false, true, true, false, true, true, false, true, true, true, true],
    [false, true, false, false, true, false, false, true, false, false, true, false, false, true, false],
     [true, true, true, false, false, true, true, true, true, true, false, false, true, true, true],
     [true, true, true, false, false, true, true, true, true, false, false, true, true, true, true],
    [true, false, true, true, false, true, true, true, true, false, false, true, false, false, true],
    [true, true, true, true, false, false, true, true, true, false, false, true, true, true, true],
     [true, true, true, true, false, false, true, true, true, true, false, true, true, true, true],
     [true, true, true, false, false, true, false, false, true, false, false, true, false, false, true],
     [true, true, true, true, false, true, true, true, true, true, false, true, true, true, true],
     [true, true, true, true, false, true, true, true, true, false, false, true, true, true, true],
  ]
  
    const startPos = 9;
  const { updateScore } = useCustomContext();
  const [countdown, setCountdown] = useState(startPos);
  useEffect(() => {
    const interval = setInterval(() => {
     // setColor(colors[getRandomInt(0, colors.length)])
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
      else {
        updateScore(1);
        setCountdown(startPos);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);
  return (
    <main>
     <Info page="pixel" />
     <div className="pixel-container">
        {
          times[countdown].map((item, index) => {
            return(
              <div key={index} style={{backgroundColor: item ? "#111" : "#fff"}}></div>
            )
          })
        }
      </div>
    </main>
  );
}

export default App;
