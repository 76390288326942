import React, { useState, useEffect} from 'react';

import Info from '../components/Info';

const data = [
  ""
]
function App() {

  const [greeting, setGreeting] = useState("");
  const [homeScreen, setHomeScreen] = useState(true);
  useEffect(() => {
    const determineGreeting = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 5 && currentHour < 12) {
        setGreeting('Good Morning');
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting('Good Afternoon');
      } else if (currentHour >= 18 && currentHour < 22) {
        setGreeting('Good Evening');
      } else {
        setGreeting('Good Night');
      }
    };

    determineGreeting();

    // Update the greeting every minute
    const interval = setInterval(determineGreeting, 60 * 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <main>
     <Info page="test" />
      <div className="test"></div>
      {
        homeScreen &&
        <>
          <p className="center">{greeting} Patient!</p>
          <button onClick="">Start Test</button>
        </>
      }
    </main>
  );
}

export default App;
