import React, { useState, useEffect } from 'react';
import data from '../constants/words';
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore} = useCustomContext();
  const [answer, setAnswer] = useState();
  const [guess, setGuess] = useState('')
  const [message, setMessage] = useState('Guess my word:')
  const [words, setWords] = useState(data);
  const [level, setLevel] = useState(4);
  const [scrambled, setScrambled] = useState("");
  const [reload, setReload] = useState(false);
  function makeGuess(e) {
    e.preventDefault();
    if (!guess) {
      setMessage('Please make a guess')
      return;
    }
    if (guess === answer) {
      setMessage('You won! +' + (level - 3) + ' points. Guess my word:')
      updateScore(level - 3);
      if (level < 10) {
        setLevel(old => old + 1)
      }
      setReload(old => !reload)
    } else {
      setMessage('Wrong. -1 point. Guess my word:')
      updateScore(-1)
     }
    setGuess("");
  }

  function scrambleWord(inputWord) {
    const wordArray = inputWord.split('');
    for (let i = wordArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [wordArray[i], wordArray[j]] = [wordArray[j], wordArray[i]];
    }
    const scrambled = wordArray.join('');
    return scrambled;
  };
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  useEffect(() => {
    updateScore(1);
    const theAnswer = words[level][getRandomInt(0, words[level].length)];
    setAnswer(theAnswer);
    setScrambled(scrambleWord(theAnswer))

  }, [reload])
  
  return (
    <main>
      <Info page="word-scramble" />
      <p>{message}</p>
      <p>{scrambled}</p>
      <form onSubmit={(e) => makeGuess(e)}>
      <input type="text" value={guess} onChange={(e) => setGuess(e.target.value)} placeholder="Enter your guess" />
      </form>
    </main>
  );
}

export default App;
