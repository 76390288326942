import React, { useState, useEffect} from 'react';

import { useCustomContext } from '../state/context';
function App() {

  const { siteData } = useCustomContext();
  
  return (
    <main style={{alignItems: "flex-start"}}>
      <h3>The Noun Project</h3>
      <p>All the icons on this site are from The Noun Project. Please visit the links below for the sources.</p>
    <div className="noun">
      {
        siteData.map((item, index) => {
          return(
            <div key={index}>
              <img src={require('../img/' + item.img)} alt={item.name} />
              <div>
                <p><a href={"/" + item.url}>{item.name}</a></p>
                <p><a href={item.noun}>{item.noun}</a></p>
              </div>
            </div>
          )
        })
      }
      </div>
    </main>
  );
}

export default App;
