import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';
import { useCustomContext } from '../state/context';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const { score, siteData, currentPage, updatePage } = useCustomContext();
  const [selectedOption, setSelectedOption] = useState('all');
  const [links, setLinks] = useState(siteData);

  useEffect(() => {
    // Filter links based on radio button selection
    const filtered = siteData.filter((item) => {
      if (selectedOption === 'all') {
        return true;
      } else if (item.type === 'utility' && selectedOption === 'apps') {
        return true;
      } else if (item.type === 'game' && selectedOption === 'games') {
        return true;
      } else if (item.type === 'other' && selectedOption === 'other') {
        return true;
      } else {
        return false;
      }
    });

    setLinks(filtered);
  }, [selectedOption, siteData]);

  return (
    <>
      <header>
        <h1>
          <Link to="/">Apps</Link></h1>
          <p>by <Link to="https://matthewgruman.com">Matthew Gruman</Link>
        </p>
        <div className="options">
          <Form.Check
            type="radio"
            id={`all`}
            label={`All`}
            checked={selectedOption === 'all'}
            onChange={() => setSelectedOption('all')}
          />
          <Form.Check
            type="radio"
            id={`apps`}
            label={`Apps`}
            checked={selectedOption === 'apps'}
            onChange={() => setSelectedOption('apps')}
          />

          <Form.Check
            type="radio"
            id={`games`}
            label={`Games`}
            checked={selectedOption === 'games'}
            onChange={() => setSelectedOption('games')}
          />

          <Form.Check
            type="radio"
            id={`other`}
            label={`Other`}
            checked={selectedOption === 'other'}
            onChange={() => setSelectedOption('other')}
          />
        </div>
        {/* <p className="points">Points: {score ? score : 0}</p> */}
        <div>
          <ul>
            {links.map((item, index) => (
              <a href={'/' + item.url}>
              <li key={index}>
                {item.name}
              </li></a>
            ))}
          </ul>
        </div>
      </header>
    </>
  );
}
