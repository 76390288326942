import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");
  const [reload, setReload] = useState(false);
  const [animals, setAnimals] = useState([])
  const [names, setNames] = useState([]);
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(true);
  const [choices, setChoices] = useState([]);
  const [answer, setAnswer] = useState('');
  const [currentGame, setCurrentGame] = useState("Animal")

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }


  useEffect(() => {
    axios.get("https://random-generators.vercel.app/animals/all")
    .then(results => {
      setAnimals(results.data)
    })
    .catch(err => {
      console.log(err);
    })

    axios.get("https://random-generators.vercel.app/names/all")
    .then(results => {
      setNames(results.data)
    })
    .catch(err => {
      console.log(err);
    })

    axios.get("https://random-generators.vercel.app/names/1000")
    .then(results => {
      setNames(results.data)
    })
    .catch(err => {
      console.log(err);
    })

    axios.get("https://random-generators.vercel.app/countries/all")
    .then(results => {
      setCountries(results.data)
    })
    .catch(err => {
      console.log(err);
    })

    setLoading(false);
  }, [])

  useEffect(() => {
    if (!loading) {
      
    }
  }, [reload, loading])

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  const loadGame = (type) => {
    let newChoices = [];
    if (type === "animal") {
      const temp = [...animals];
      shuffle(temp)
      newChoices.push(temp[0]);

      let databases = [];
      databases.push(...names);
      databases.push(...countries);
      shuffle(databases);
      newChoices.push(...databases.slice(0, 9));
      shuffle(newChoices)
      setChoices(newChoices);
      setCurrentGame("Which one is an animal?")
    }
  }
  return (
    <main>
     <Info page="wrestler" />
     <h2>{currentGame}</h2>
     <ul className="find">
     {
        choices.length > 0 &&
          choices.map((item, index) => {
            return(
              <li key={index}>{item}</li>
            )
          })
     }
     </ul>
        <button onClick={() => loadGame("animal")}>Reload</button>
    </main>
  );
}

export default App;
