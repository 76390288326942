import React, { useState, useEffect} from 'react';

import { useCustomContext } from '../state/context';
import Info from '../components/Info';
import { Table } from 'react-bootstrap';

const data = [
  {
    name: "One",
    elo: 1500
  },
  {
    name: "Two",
    elo: 1500
  },
  {
    name: "Three",
    elo: 1500
  },
  {
    name: "Four",
    elo: 1500
  }
]
function App() {

  const { updateScore } = useCustomContext();
  const [ideas, setIdeas] = useState([]);
  const [ideaBox, setIdeaBox] = useState('');
  const [sortedIdeas, setSortedIdeas] = useState([]);
  const [reload, setReload] = useState(false);

  function enterIdeas() {
    if (!ideaBox) {
      return;
    }
    const val = ideaBox.split("\n");
    let array = [];
    for (let i = 0; i < val.length; i++) {
      const toPush = {
        name: val[i],
        elo: 1500
      }
      array.push(toPush);
    }
    setIdeas(shuffle(array))
  }

  useEffect(() => {
    if (ideas.length > 0) {
    setSortedIdeas(ideas.slice().sort((a, b) => b.elo - a.elo));
    }
  }, [ideas])

  function vote(win, lose) {
    const elos = getElo(100, ideas[win].elo, ideas[lose].elo)
    
    let players = [...ideas];
    players[win].elo = elos.winNewElo;
    players[lose].elo = elos.loseNewElo;

    const shuffledIdeas = shuffle([...ideas]); // Create a new array with the shuffled order
    setIdeas(shuffledIdeas);
  }

  function getElo(length, winElo, loseElo) {
    let d = winElo - loseElo;
    let elo = Math.ceil((4 * Math.sqrt(length)) / (1 + Math.pow(10, d * Math.sqrt(length / 2000))))
  
    let d2 = loseElo - winElo;
    let elo2 = Math.ceil((4 * Math.sqrt(length)) / (1 + Math.pow(10, -d2 * Math.sqrt(length / 2000))))
  
    return {
      winNewElo: winElo + elo,
      loseNewElo: loseElo - elo2
    }
  }

  function reset() {
    setIdeas([]);
  }
  function shuffle(array) {
    let currentIndex = array.length, randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  
  return (
    <main>
     <Info page="mind" />
      {
        ideas.length > 0 ? 
        <>
          <p>Which do you prefer?</p>
          <button onClick={() => vote(0, 1)}>{ideas[0].name}</button>
          <p>Or</p>
          <button onClick={() => vote(1, 0)}>{ideas[1].name}</button>
          <h2>Rankings</h2>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Idea</th>
                <th>Elo</th>
              </tr>
            </thead>
            <tbody>
              {
                sortedIdeas.map((item, index) => {
                  return(
                    <tr key={index}>
                      <td>{index+1}</td>
                      <td>{item.name}</td>
                      <td>{item.elo}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
          <button onClick={() => reset()}>Reset</button>
        </>
        :
        <>
          <p>Enter your ideas one line at a time:</p>
          <textarea value={ideaBox} onChange={(e) => setIdeaBox(e.target.value)} />
          <button onClick={() => enterIdeas()}>Submit</button>
        </>
        }
    </main>
  );
}

export default App;
