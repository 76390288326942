import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { useCustomContext } from '../state/context';
function App() {

  const { updateScore } = useCustomContext();
  const slug = useParams();
  
  useEffect(() => {
    updateScore(1);
    axios.get("https://url-shortener-gamma-two.vercel.app/" + slug.slug)
    .then (results => {
      console.log(results.data)
      window.location.href = "https://" + results.data;

    })
    .catch(err => {
      console.log(err)
    })
  },[])

  return (
    <main style={{minHeight: "80vh", justifyContent: "center", display: "flex"}}>
      
      <p className="sub">Tiny Apps by <a href="https://matthewgruman.com">Matthew Gruman</a>.</p>
      
    </main>
  );
}

export default App;
