import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    axios.get("https://bio-generator-api.vercel.app")
    .then(results => {
      setBio(results.data)
      updateScore(1)
    })
    .catch(err => {
      console.log(err);
    })
  }, [reload])
  return (
    <main>
     <Info page="bio" />
      <p>{bio}</p>
        <button onClick={() => setReload(old => !old)}>Reload</button>
    </main>
  );
}

export default App;
