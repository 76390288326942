import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");
  const [reload, setReload] = useState(false);
  const [animals, setAnimals] = useState(false)
  const [names, setNames] = useState(false);
  const [name, setName] = useState("")

  useEffect(() => {
    axios.get("https://random-generators.vercel.app/animals/all")
    .then(results => {
      setAnimals(results.data)
    })
    .catch(err => {
      console.log(err);
    })

    axios.get("https://random-generators.vercel.app/names/all")
    .then(results => {
      setNames(results.data)
      console.log(results.data);
    })
    .catch(err => {
      console.log(err);
    })
  }, [])

  useEffect(() => {
    if (animals && names) {
      setName(names[getRandomInt(0, names.length)] + ' "The ' + animals[getRandomInt(0, animals.length)] + '" ' + names[getRandomInt(0, names.length)]);
    }
  }, [reload, animals, names])

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  return (
    <main>
     <Info page="wrestler" />
      <p>{name}</p>
        <button onClick={() => setReload(old => !old)}>Reload</button>
    </main>
  );
}

export default App;
