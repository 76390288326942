import React, { useState, useEffect, useRef, useCallback} from 'react';
import Webcam from "react-webcam";
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {
  const webcamRef = useRef(null); // create a webcam reference
  const [imgSrc, setImgSrc] = useState(null); // initialize it

  const { updateScore } = useCustomContext();

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  return (
    <main>
     <Info page="camera" />
     {imgSrc ? (
        <img src={imgSrc} alt="webcam" />
      ) : (
        <Webcam height={300} width={400} ref={webcamRef} />
      )}
       {imgSrc ? (
          <button onClick={retake} style={{marginTop: "2rem"}}>Retake photo</button>
        ) : (
          <button onClick={capture} style={{marginTop: "2rem"}}>Take photo</button>
        )}
    </main>
  );
}

export default App;
