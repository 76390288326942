import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
import { Unity, useUnityContext } from 'react-unity-webgl';

function App() {

  const { updateScore } = useCustomContext();
  const unityContext = useUnityContext({
    loaderUrl: '/unity/100px/loader.js',
    dataUrl: '/unity/100px/data',
    frameworkUrl: '/unity/100px/framework.js',
  });
  return (
    <main>
     <Unity unityContext={unityContext} />
     
    </main>
  );
}

export default App;
