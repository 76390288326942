import React, { useState, useEffect } from 'react';

import Info from '../components/Info';

import { useCustomContext } from '../state/context';
const generateCards = () => {
  // In a real game, you'd have more sophisticated logic for generating pairs
  const symbols = ['#41B0F6', '#64C3FF', '#95D6FF', '#BDE5FF', '#009CFF', '#0089DF', '#006AAC', '#025285'];
  const cards = symbols.concat(symbols);
  return cards.sort(() => Math.random() - 0.5);
};

const CardMemoryGame = () => {

  const { updateScore} = useCustomContext();
  const [cards, setCards] = useState(generateCards());
  const [flippedIndices, setFlippedIndices] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);

  const handleCardClick = (index) => {
    if (flippedIndices.length === 2) {
      return;
    }
    if (flippedIndices.includes(index)) {
      return;
    }

    setFlippedIndices(prevFlippedIndices => [...prevFlippedIndices, index]);
    let prevFlippedIndices = [...flippedIndices, index];
    if (flippedIndices.length === 1) {
      // Check for a match after a short delay
      setTimeout(() => checkForMatch(prevFlippedIndices), 800);
    }
  };

  const checkForMatch = (indices) => {
    const [index1, index2] = indices;
    console.log(indices)
    if (cards[index1] === cards[index2]) {
      setMatchedPairs([...matchedPairs, cards[index1]]);
      updateScore(1);
    }
    else {
      updateScore(-1)
    }

    setFlippedIndices([]);
  };

  const resetGame = () => {
    setCards(generateCards());
    setFlippedIndices([]);
    setMatchedPairs([]);
  };

  useEffect(() => {
    if (matchedPairs.length === cards.length / 2) {
      updateScore(10);
      alert('Congratulations! You won!');
      resetGame();
    }
  }, [matchedPairs, cards.length]);

  return (
    <main>
      <Info page="memory" />
      <div className="memory">
        {cards.map((symbol, index) => (
          <div
          style={{backgroundColor: flippedIndices.includes(index) || matchedPairs.includes(symbol) ? symbol : '#0376BB'}}
        
            key={index}
            onClick={() => handleCardClick(index)}
            className={`card ${flippedIndices.includes(index) || matchedPairs.includes(symbol) ? 'flipped' : ''}`}
          > 
          </div>
        ))}
      </div>
      <button onClick={resetGame}>Reset Game</button>
    
    </main>
  );
};

export default CardMemoryGame;
