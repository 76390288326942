import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';

function App() {

  const { updateScore } = useCustomContext();
  const [word, setWord] = useState("Hello");
  const [words, setWords] = useState([]);
  const [ant, setAnt] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (word) {
const api_url = `https://api.api-ninjas.com/v1/thesaurus?word=${word}`;
const apiKey = 'oVnhODrhmGiaA6pjCK4DMg==Zbhvt4i17kwaYZvB';

axios.get(api_url, {
  headers: {
    'X-Api-Key': apiKey,
  },
})
  .then(response => {
    if (response.status === 200) {
      console.log(response.data)
      setWords(response.data.synonyms);
      setAnt(response.data.antonyms);
    } else {
      console.log(`Error: ${response.status}`, response.data);
    }
  })
  .catch(error => {
    console.error('Error:', error);
  });
}
  }, [word])
  return (
    <main className="main-left">
     <Info page="thesaurus" />
      
      <input type="text" value={word} onChange={(e) => setWord(e.target.value)} />
      <div className="two-col">
      <div>
      <h4>Synonyms</h4>
      <ul>
      {
        words &&
          words.map((item, index) => {
            return(
              <li key={index}>{item}</li>
            )
          })
      }
      </ul>
      </div><div>
      <h4>Antonyms</h4>
      <ul>
      {
        ant &&
          ant.map((item, index) => {
            return(
              <li key={index}>{item}</li>
            )
          })
      }
      </ul>
      </div>
      </div>
    </main>
  );
}

export default App;
