import React, { useState, useEffect} from 'react';

import Info from '../components/Info';


function App() {

  const [squares, setSquares] = useState([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15])
  const [currentSquare, setCurrentSquare] = useState(0);
  

  useEffect(() => {
    let array = [];
    for (let i = 0; i < 100; i++) {
      array.push(i);
    }
    setSquares(array)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSquare(old => {
        if (old < squares.length - 1) {
          return old + 1;
        } else {
          console.log("Hello");
          return 0;
        }
      });
    }, 100);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [squares.length]);

  return (
    <main>
     <Info page="animation" />
     
     <div className="animation2">
     {
      squares.map((item, index) => {
        return(
          <div key={index} style={{backgroundColor: item === currentSquare ? '#111' : ''}}>
           
            </div>
        )
      })
     }
     </div>
    </main>
  );
}

export default App;
