import React, { useState, useEffect } from 'react';
import data from '../constants/words';
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore} = useCustomContext();
  const [inputText, setInputText] = useState('');
  const [shuffledText, setShuffledText] = useState('');

  const handleInputChange = (event) => {
    const newText = event.target.value;
    setInputText(newText);
  };

  const handleSpacePress = (event) => {
    if (event.code === 'Space') {
      shuffleWords();
    }
    updateScore(1)
  };

  const shuffleWords = () => {
    const words = inputText.split(' ');
    const shuffledWords = words.sort(() => Math.random() - 0.5);
    const shuffledText = shuffledWords.join(' ');
    setShuffledText(shuffledText);
  };

  
  return (
    <main>
      <Info page="word-jumble" />
      
        <p>{shuffledText}</p>
        <textarea
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleSpacePress}
        placeholder="Type here..."
      />
    </main>
  );
}

export default App;
