import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Spinner } from 'react-bootstrap';
import Info from '../components/Info';
import { useCustomContext } from '../state/context';
//const api = "https://battlers-api.vercel.app";
const api = "http://localhost:8082"
function App() {

  const { updateScore } = useCustomContext()
  const [totalMatches, setTotalMatches] = useState('');
  const [battlers, setBattlers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await axios.get(api + "/getAll");
        const data = results.data;
        let wins = 0;
        for (let i = 0; i < data.length; i++) {
          wins += data[i].wins
        }
        setTotalMatches(wins)
        const sorted = sortByPropertyDescending(data, "elo");
        setBattlers(sorted)
       playGame();
        setLoading(false);

      } catch (error) {
        console.log(error);
      }
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 1000 milliseconds
    //const intervalId = setInterval(fetchData, 10);

    // Clean up the interval on component unmount
    return () => {
      //clearInterval(intervalId);
    };
  }, []);


useEffect(() => {
  axios.get("http://localhost:8082/getPlayers")
  .then(results => {
    setPlayers(results.data)
  })
  .catch(err => {
    console.log(err);
  })
}, [])

  function playGame() {
    axios.get(api + "/playGame")
      .then(result => {
        console.log(result.data)
        
      })
      .catch(err => {
        console.log(err);
      })
  }
  function sortByPropertyDescending(array, property) {
    return array.slice().sort((a, b) => {
      if (a[property] > b[property]) {
        return -1;
      }
      if (a[property] < b[property]) {
        return 1;
      }
      return 0;
    });
  }
  return (
    <main>
      {
    loading ?
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spinner size="lg" />
      </div>
      :
      <>
      <Info page="grucha-libre" />
      <div className="cute">
        <div>
          <img src={require('../img/' + players[0].img)} alt="I mean, a dog" />
          <h3>{players[0].name}</h3>
        </div>
      </div>
      <p>Total battles: {totalMatches}</p>
        {/* <button onClick={() => addBattler()}>Add Gruchador</button> */}
        <Table>

          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Str</th>
              <th>Def</th>
              <th>Spd</th>
              <th>Wins</th>
              <th>Losses</th>
              <th>Exp</th>
              <th>Level</th>
              <th>Elo</th>
            </tr>
            </thead>
            <tbody>
            {
              battlers ? battlers.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.str}</td>
                    <td>{item.def}</td>
                    <td>{item.spd}</td>
                    <td>{item.wins}</td>
                    <td>{item.losses}</td>
                    <td>{item.exp}</td>
                    <td>{item.level}</td>
                     <td>{item.elo}</td>
                  </tr>
                )
              })
              :
              ""
            }
          </tbody>
        </Table>
        </>
}
    </main>
  );
}

export default App;
