import React, { createContext, useContext, useState, useEffect } from 'react';
import data from '../constants/links';
data.sort((a, b) => {
  // Convert names to lowercase for case-insensitive sorting
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  // Compare the names
  if (nameA < nameB) {
      return -1;
  }
  if (nameA > nameB) {
      return 1;
  }
  return 0; // Names are equal
});
const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [score, setScore] = useState(0);
  const [siteData, setSiteData] = useState(data);

  const updateScore = (num) => {
    setScore(old => old + num);
  }
  return (
    <Context.Provider value={{siteData, score, updateScore }}>
      {children}
    </Context.Provider>
  );
};

export const useCustomContext = () => useContext(Context);