import React, { useState, useEffect, useRef } from 'react';
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
import data from '../constants/battlers';

function App() {
  const { updateScore } = useCustomContext();

  const [battlers, setBattlers] = useState(data);
  const [messages, setMessages] = useState(["Press Battle to start."])
  const [gameStarted, setGameStarted] = useState(false);

  const currentTurnRef = useRef(0);

  function updateHP(id, health) {
    let message = battlers[id].name + " does " + health + " damage";
    setMessages(old => [message, ...old])
    setBattlers(prevBattlers => {
      const newBattlers = [...prevBattlers];
      newBattlers[id].currentHP -= health;
      return newBattlers;
    });
  }

  useEffect(() => {
    if (!gameStarted) {
      let newBattlers = [...battlers];
      for (let i = 0; i < newBattlers.length; i++) {
        newBattlers[i].currentHP = newBattlers[i].hp;
      }
      setBattlers(newBattlers);
    }
  }, [gameStarted]);

  useEffect(() => {
    if (gameStarted) {
      const playTurn = () => {
        setTimeout(() => {
          updateHP(currentTurnRef.current, battlers[currentTurnRef.current].str);
          currentTurnRef.current++;
          if (currentTurnRef.current > 1) {
            currentTurnRef.current = 0;
          }

          if (battlers[0].currentHP > 0 && battlers[1].currentHP > 0) {
            playTurn(); // Continue playing turns if both battlers have HP
          } else {
            setGameStarted(false);
          }
        }, 100);
      };

      playTurn(); // Start the first turn
    }
  }, [gameStarted]);

  return (
    <main>
      <Info page="bio" />
      <div className="battlers">
        {
          battlers.map((item, index) => {
            return (
              <div key={index}>
                <h3>{item.name}</h3>
                <p>{item.currentHP}/{item.hp}</p>
                <ul>
                  <li>ATK: {item.atk}</li>
                  <li>STR: {item.str}</li>
                  <li>DEF: {item.def}</li>
                  <li>SPD: {item.spd}</li>
                </ul>
              </div>
            )
          })
        }
      </div>
      <div className="auto-panel">
        {
          messages.map((item, index) => {
            return(
              <p key={index}>{item}</p>
            )
          })
          }
      </div>
      {
        !gameStarted &&
        <button onClick={() => setGameStarted(true)}>Start</button>
      }
    </main>
  );
}

export default App;
