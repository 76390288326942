import React, {useState, useEffect } from 'react';
import axios from 'axios';

import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext()
  const [hex, setHex] = useState("");
  const [reload, setReload] = useState(false);

  
  useEffect(() => {
    axios.get("https://random-hex.vercel.app")
      .then(results => {
        setHex(results.data);
        updateScore(1);
      })
      .catch(err => {
        console.log(err);
      })
  }, [reload])
  return (
    <main>
    <Info page="hex" />
          <button onClick={() => setReload(old => !old)}>Reload</button>
       <div className="hex" style={{backgroundColor: hex.hex}}>  
        <span className="hexCode" style={{color: hex.text}}>{hex.hex}</span>
        </div>
    </main>
  );
}

export default App;
