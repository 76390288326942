import React, { useState, useEffect} from 'react';

import axios from 'axios'

import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [smell, setSmell] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    axios.get("https://how-do-i-smell.vercel.app")
    .then(results => {
      setSmell(results.data.message)
      updateScore(1);
    })
    .catch(err => {
      console.log(err);
    })
  }, [reload])
  return (
    <main>
     <Info page="smell" />
      <p>{smell}</p>
      <button onClick={() => setReload(old => !old)}>Reload</button>
    </main>
  );
}

export default App;
