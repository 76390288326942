import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { getCroppedImg, applyGrayscaleFilter } from '../utils/utils'; // You need to implement these utility functions

function App() {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");
  const [reload, setReload] = useState(false);

  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const applyFilter = () => {
    const filteredImage = applyGrayscaleFilter(image); // Replace with your filter logic
    setImage(filteredImage);
  };

  const saveImage = () => {
    const canvas = document.createElement('canvas');
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const croppedImage = getCroppedImg(img, crop, zoom);
      canvas.width = croppedImage.width;
      canvas.height = croppedImage.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(croppedImage, 0, 0, croppedImage.width, croppedImage.height);

      const dataURL = canvas.toDataURL('image/png');
      console.log('Save this dataURL or send it to the server:', dataURL);
    };
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <main>
     <Info page="bio" />
     
     <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <p>Drag & drop an image here, or click to select one</p>
      </div>
      {image && (
        <div>
          <img src={image} alt="Selected" style={imageStyle} />
          <button onClick={applyFilter}>Apply Filter</button>
          <button onClick={saveImage}>Save Image</button>
        </div>
      )}
      </main>
  );
}
const imageStyle = {
  maxWidth: '100%',
  maxHeight: '400px',
  margin: '20px 0',
};
const dropzoneStyle = {
  border: '2px dashed #aaa',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};


export default App;
