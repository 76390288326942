import React, { useState, useEffect} from 'react';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useCustomContext } from '../state/context';
import { Container, Button, Form } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Info from '../components/Info';
import axios from 'axios';


function App() {

  const [lines, setLines] = useState([]);
  const { updateScore } = useCustomContext();
  const [fact, setFact] = useState("");
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(0);
  const [words, setWords] = useState('');
  const [numWords, setNumWords] = useState(25);
  const [copied, setCopied] = useState(false);
  const [nervous, setNervous] = useState(false);
  const [type, setType] = useState("mental");

  useEffect(() => {
    axios.post('https://lorem-pickup.vercel.app', {
      num: 10,
      type: type,
      nervous: nervous
    })
    .then(results => {
      setWords(results.data)
    })
    .catch(err => {
      console.log(err);
    })
  }, [numWords, nervous, type])


  function nervousMe() {
    updateScore(1);
    setNervous(!nervous);
  }

  function copyMe() {

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500)
  }

 
  return (
    <main>
     <Info page="lorem" />
     <div className="categories" style={{marginBottom: "1rem"}}>
      <button onClick={() => setType("mental")}>Mental</button>
      <button onClick={() => setType("cows")}>Cow</button>
      <button onClick={() => setType("pickup")}>Pickup</button>
      <button onClick={() => setType("rock")}>Rock</button>
     </div>
     <Form style={{textAlign: "center"}}>
        <textarea value={words} className=" mb-2" style={{fontSize: "1rem"}} />
        <Form.Range onChange={(e) => setNumWords(e.target.value)} value={numWords} className="custom-range" variant="light" />
        <div className="bottom">
          <p>Slide to generate {numWords} lines.</p>
          <p onClick={() => nervousMe()}>Nervous mode: {nervous ? "ON" : "OFF"}</p>
         
        </div>
      </Form>
      <CopyToClipboard text={words}
        onCopy={() => copyMe()}>
        <button variant="light" className="mb-2 mt-2">Copy to clipboard</button>
      </CopyToClipboard>
      {
        copied ?
          <p>Copied!</p>
          : ''
      }
    </main>
  );
}

export default App;
