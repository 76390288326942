import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
import moment from 'moment'
function App() {

  const { updateScore } = useCustomContext();
  

  useEffect(() => {
    if (!localStorage.getItem("life")) {
      localStorage.setItem("life", Date.now());
    }
    console.log(localStorage.getItem("life"))
    console.log(Date.now())
  },[])
  return (
    <main>
     <Info page="bio" />
     <p>You first visited this page: {moment(parseInt(localStorage.getItem("life"))).format('MMMM Do YYYY, h:mm:ss a')}</p>
     <p>You last visited this page: {moment(Date.now() - parseInt(localStorage.getItem("life"))).format('MMMM Do YYYY, h:mm:ss a')}</p>
    </main>
  );
}

export default App;
