import React, { useState, useEffect, useCallback } from 'react';

import { useCustomContext } from '../state/context';
import Info from '../components/Info';

const originals = [
  {
    id: 0,
    name: "Matthew",
    hp: 50,
    str: 1,
    def: 1,
    spd: 1,
    wins: 0,
    losses: 0,
    elo: 1500
  }, {
    id: 1,
    name: "Gruman",
    hp: 50,
    str: 1,
    def: 1,
    spd: 1,
    wins: 0,
    losses: 0,
    elo: 1500
  }
]

function App() {

  const { updateScore } = useCustomContext();
  const [players, setPlayers] = useState(originals);
  const [isPaused, setIsPaused] = useState(true);

  function getRandomDecimal(min, max) {
    const decimalPlaces = 2;
    const multiplier = Math.pow(10, decimalPlaces);
    min = Math.ceil(min * multiplier);
    max = Math.floor(max * multiplier);
    const randomValue = Math.floor(Math.random() * (max - min + 1) + min) / multiplier;
    return randomValue;
  }

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const playGame = useCallback(() => {

    let battlers = [...players];
    console.log(players);
    shuffle(battlers);
    let hps = [battlers[0].hp, battlers[1].hp]

    while (hps[0] > 0 && hps[1] > 0) {
      let successChance = 0.5 + (battlers[0].spd - battlers[1].spd) / 100;
      let isAttackSuccessful = Math.random() < successChance;
      if (isAttackSuccessful) {
        //hit
        const baseDamage = getRandomDecimal(5, 15); // Adjust as needed
        const modifiedDamage = Math.max(0, baseDamage + battlers[0].str - battlers[1].def);

        hps[1] = hps[1] - modifiedDamage;
        if (hps[1] < 1) {
          break;
        }
      }
      successChance = 0.5 + (battlers[1].spd - battlers[0].spd) / 100;
      isAttackSuccessful = Math.random() < successChance;
      if (isAttackSuccessful) {
        //hit
        const baseDamage = getRandomDecimal(5, 15); // Adjust as needed
        const modifiedDamage = Math.max(0, baseDamage + battlers[1].str - battlers[0].def);

        hps[0] = hps[0] - modifiedDamage;
        if (hps[0] < 1) {
          break;
        }
      }
    }
    if (hps[0] > hps[1]) {
      const { winNewElo, loseNewElo } = getElo(battlers[0].elo, battlers[1].elo);

      updateValue(battlers[0].id, "wins", 1)
      updateValue(battlers[1].id, "losses", 1)
      changeValue(battlers[0].id, "elo", winNewElo)
      changeValue(battlers[1].id, "elo", loseNewElo)
    } else {
      const { winNewElo, loseNewElo } = getElo(battlers[1].elo, battlers[0].elo);

      updateValue(battlers[1].id, "wins", 1)
      updateValue(battlers[0].id, "losses", 1)
      changeValue(battlers[1].id, "elo", winNewElo);
      changeValue(battlers[0].id, "elo", loseNewElo);
    }
  }, [players])

  useEffect(() => {
    const fetchData = () => {
      if (!isPaused) {
      playGame();
      }
    };
  
    // Load players from local storage on component mount
    setPlayers(loadPlayersFromLocalStorage());
  
    // Fetch data every 1000 milliseconds
    const intervalId = setInterval(fetchData, 1000);
  
    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [isPaused]);

  const updateValue = (playerId, property, increment = 1) => {
    setPlayers((prevPlayers) => {
      const updatedPlayers = prevPlayers.map((player) => {
        if (player.id === playerId) {
          return { ...player, [property]: player[property] + increment };
        }
        return player;
      });
      savePlayersToLocalStorage(updatedPlayers); // Save to local storage
      return updatedPlayers;
    });
  };
  
  const changeValue = (playerId, property, newValue) => {
    setPlayers((prevPlayers) => {
      const updatedPlayers = prevPlayers.map((player) => {
        if (player.id === playerId) {
          return { ...player, [property]: newValue };
        }
        return player;
      });
      savePlayersToLocalStorage(updatedPlayers); // Save to local storage
      return updatedPlayers;
    });
  };
  function getElo(winElo, loseElo, length = 20) {
    let d = winElo - loseElo;
    let elo = Math.ceil((4 * Math.sqrt(length)) / (1 + Math.pow(10, d * Math.sqrt(length / 2000))))

    let d2 = loseElo - winElo;
    let elo2 = Math.ceil((4 * Math.sqrt(length)) / (1 + Math.pow(10, -d2 * Math.sqrt(length / 2000))))

    return {
      winNewElo: winElo + elo,
      loseNewElo: loseElo - elo2
    }
  }
  const savePlayersToLocalStorage = (players) => {
    localStorage.setItem('players', JSON.stringify(players));
  };

  const loadPlayersFromLocalStorage = () => {
    const storedPlayers = localStorage.getItem('players');
    return storedPlayers ? JSON.parse(storedPlayers) : originals;
  };
  const resetPlayersToOriginal = () => {
    setPlayers(originals);
    savePlayersToLocalStorage(originals);
  };
  return (
    <main>
      <Info page="forever" />
      <div className="buttons">
      <button onClick={() => setIsPaused(old => !old)}>
        {isPaused ? "Play" : "Pause"}
      </button>
      <button onClick={() => resetPlayersToOriginal()}>
        Reset
      </button>
      </div>
      <div className="forever">
        {
          players.map((item, index) => {
            return (
              <div key={item.id}>
                <h4>{item.name}</h4>
                <ul>
                  <li>
                    Wins: {item.wins}
                  </li>
                  <li>
                    Losses: {item.losses}
                  </li>
                  <li>
                    Average: {(item.wins / (item.losses + item.wins) * 100).toFixed(2)}%
                  </li>
                  <li className="stat-break">
                    Elo: {item.elo}
                  </li>

                  <li onClick={() => updateValue(item.id, "hp")}>
                    Hit points: {item.hp}
                  </li>
                  <li onClick={() => updateValue(item.id, "str")}>
                    Strength: {item.str}
                  </li>
                  <li onClick={() => updateValue(item.id, "def")}>
                    Defense: {item.def}
                  </li>
                  <li onClick={() => updateValue(item.id, "spd")}>
                    Speed: {item.spd}
                  </li>
                </ul>
              </div>)
          })
        }

      </div>
      <p>The person who goes first is a coin toss. Speed determines if you hit or miss. Strength is how much damage you do, and defense is how much damage is absorbed. The match ends when one person's hit points is 0.</p>
      
    </main>
  );
}

export default App;
