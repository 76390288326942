import React, { useState, useEffect} from 'react';

import scrabble from '../constants/scrabble'
import { useCustomContext } from '../state/context';

import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [words, setWords] = useState(scrabble);
  const [word, setWord] = useState("")
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (words.find(original => original === word)) {
      updateScore(1);
      setMessage(word + " is a valid Scrabble word.")
    }
    else {
      setMessage(word + " is not a valid Scrabble word.")
      updateScore(-1);
    }
  }, [word])
  return (
    <main>
     <Info page="scrabble" />
      <input type="text" value={word} onChange={(e) => setWord(e.target.value)} />
      {
        message && 
        <p>{message}</p>
        
      }
    </main>
  );
}

export default App;
