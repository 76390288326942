import React, {useState, useEffect } from 'react';
import axios from 'axios';

import colors from '../constants/colors';
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext()
  const [fact, setFacts] = useState([])
  const [reload, setReload] = useState(false);
  const [theme, setTheme] = useState(0)
  function calculateTextColor(backgroundColor) {
    // Convert the background color to RGB values
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);
  
    // Calculate the relative luminance (brightness) of the background color
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // Choose white or black text color based on the luminance
    return luminance > 0.5 ? 'black' : 'white';
  }

  useEffect(() => {
    const config = {
      headers:{
        'X-Api-Key': 'oVnhODrhmGiaA6pjCK4DMg==Zbhvt4i17kwaYZvB'
      }
    }

    axios.get("https://api.api-ninjas.com/v1/facts?limit=30", config)
      .then(results => {
        setFacts(results.data);
        console.log(results.data)
        updateScore(1)
      })
      .catch(err => {
        console.log(err);
      })
  }, [reload])
  return (
    <main>
    <Info page="facts" />
          <button onClick={() => setReload(old => !old)}>Reload</button>
        <div className="facts">
          {
            fact.map((item, index) => (
              <div key={index}>
                <p>{index + 1}. {item.fact}{item.fact.endsWith('.') ? "" : "."}</p>
                </div>
            ))
          }
        </div>
    </main>
  );
}

export default App;
