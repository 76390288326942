import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';

const data = [
  "idle_f01.png",
  "idle_f02.png",
  "idle_f03.png",
  "idle_f04.png",
]
const images1 = [
  "idle_b01.png",
  "idle_b02.png",
  "idle_b03.png",
  "idle_b04.png",
]
const images2 = [
  "idle_s01.png",
  "idle_s02.png",
  "idle_s03.png",
  "idle_s04.png",
]
const images3 = [
  "walk_f01.png",
  "walk_f02.png",
  "walk_f03.png",
  "walk_f04.png",
]
const images4 = [
  "walk_b01.png",
  "walk_b02.png",
  "walk_b03.png",
  "walk_b04.png",
]
const images5 = [
  "walk_s01.png",
  "walk_s02.png",
  "walk_s03.png",
  "walk_s04.png",
]
function App() {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");
  const [reload, setReload] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [images, setImages] = useState(data);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => {
        if (prevImage + 1 < images.length) {
          return prevImage + 1;
        } else {
          return 0;
        }
      });
    }, 100);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <main>
     <Info page="animation" />
     
     <div className="animation">
      <img src={require('../img/cats/' + images[currentImage])} alt="cat" />
      <img src={require('../img/cats/' + images1[currentImage])} alt="cat" />
      <img src={require('../img/cats/' + images2[currentImage])} alt="cat" />
      <img src={require('../img/cats/' + images3[currentImage])} alt="cat" />
      <img src={require('../img/cats/' + images4[currentImage])} alt="cat" />
      <img src={require('../img/cats/' + images5[currentImage])} alt="cat" />
     </div>
    </main>
  );
}

export default App;
