import React, { useState, useEffect } from 'react';

import { useCustomContext } from '../state/context';
import { Link } from 'react-router-dom';

function App() {

  const { siteData } = useCustomContext();
  const [data, setData] = useState(siteData);
  const chosenID = Math.floor(Math.random() * siteData.length);

  function changeCategory(prop) {
    if (prop === "all") {
      setData(siteData)
    }
    else {
      const newData = [...siteData]
      const filteredData = newData.filter(item => item.type === prop);
      setData(filteredData);
    }
  }
  return (
    <main className="main-left">


      <h3 style={{ marginBottom: "1rem" }}>{siteData.length} projects</h3>
      <p>Favourite: <a href="/cow">Cow Facts</a></p>
      <p>Portfolio: <a href="https://matthewgruman.com">https://matthewgruman.com</a></p>
      <p style={{ textAlign: "center" }}>Contact: <a href="mailto: matthewgruman@gmail.com">matthewgruman@gmail.com</a></p>
      <p>Images: <a href="/noun/">The Noun Project</a></p>
      <p><a href="https://shop.gruman.co">Visit my store</a>.</p>
      
      <h2>Selected:</h2>
            <Link to={"/" + siteData[chosenID].url}>
                  <img src={require('../img/' + siteData[chosenID].img)} alt="Chosen" />
                  <p className="center">{siteData[chosenID].name}</p>
                </Link>
            
          
        
    </main>
  );
}

export default App;
