import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';

import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [timer, setTimer] = useState(120)
  const [player1Time, setPlayer1Time] = useState(120);
  const [player2Time, setPlayer2Time] = useState(120);
  const [currentPlayer, setCurrentPlayer] = useState(true);
  const [paused, setPaused] = useState(true)

  useEffect(() => {
    if (player1Time === 0 || player2Time === 0) {

      console.log('Game over!');
    }
  }, [player1Time, player2Time]);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      if (!paused) {
        if (currentPlayer && player1Time > 0) {
          setPlayer1Time((prevTime) => prevTime - 1);
        }
        else if (!currentPlayer && player2Time > 0) {

          setPlayer2Time((prevTime) => prevTime - 1);
        }
      }
    }, 1000)
    return () => {
      clearInterval(interval);
    };
  }, [currentPlayer, paused, player1Time, player2Time]);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds = String(remainingSeconds).padStart(2, '0'); // Ensure two-digit formatting
    return `${minutes}:${formattedSeconds}`;
  }
  function togglePlayers(id) {
    setPaused(false)
    updateScore(1);
    setCurrentPlayer(old => !old)
  }

  function pause()
  {
    console.log(paused)
    setPaused(old => !old)
  }

  function setupTimer(time) {
    console.log(time)
    setTimer(time)
    setPlayer1Time(time)
    setPlayer2Time(time)
  }

  function quitGame() {
    setPaused(true);
    setTimer(120)
    setPlayer1Time(120)
    setPlayer2Time(120)
    setCurrentPlayer(0)
  }

  return (
    <main>
      <Info page="chess-clock" />
     <h2>Chess Clock</h2> 
     <p>I've made two chess clock apps that you can download for Android <a href="https://play.google.com/store/apps/details?id=co.gruman.clock">here</a> and <a href="https://play.google.com/store/apps/details?id=co.gruman.chessclock&hl=en&gl=US">here</a>.</p>
      <p>Player one time: {player1Time}</p>
      <p>Player two time: {player2Time}</p>
      <button onClick={() => togglePlayers()}>Switch</button>
      <button onClick={() => pause()}>Pause</button>
    </main>
  );
}

export default App;
