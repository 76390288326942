import React, { useState, useEffect} from 'react';

import { useCustomContext } from '../state/context';
import Info from '../components/Info';

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

function App() {

  const { updateScore } = useCustomContext();
  const [bio, setBio] = useState("");
  const [reload, setReload] = useState(false);
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };
  return (
    <main>
      <Info page="login" />
      
      <button onClick={() => signInWithGoogle()}>Sign in with Google</button>
    </main>
  );
}

export default App;
