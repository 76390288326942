import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
//import './constants/firebase';

import AdsComponent from './components/AdsComponent';
//import { getAuth, onAuthStateChanged } from "firebase/auth";

import Navbar from './components/Navbar'

import { ContextProvider } from './state/context'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AI from './pages/AI';
import Thesaurus from './pages/Thesaurus';
import Animation from './pages/Animation';
import Animation2 from './pages/Animation2';
import APIs from './pages/APIs';
import Auto from './pages/Auto';
import Baseball from './pages/Baseball';
import Battlers from './pages/Battlers';
import Bio from './pages/Bio';
import Button from './pages/Button';
import Camera from './pages/Camera';
import Choice from './pages/Choice';
import Choose from './pages/Choose';
import Clock from './pages/Clock';
import Colors from './pages/Colors';
import Cow from './pages/Cow';
import Definitions from './pages/Definitions';
import Dogs from './pages/DogsAndCats';
import Dice from './pages/Dice';
import Dog from './pages/Dog';
import DogsAndCats from './pages/DogsAndCats';
import Draw from './pages/Draw';
import Facts from './pages/Facts';
import Find from './pages/Find';
import Forever from './pages/Forever';
import Food from './pages/Food';
import Grudo from './pages/Grudo';
import Hangman from './pages/Hangman';
import Hands from './pages/Hands';
import Hex from './pages/Hex';
import HigherLower from './pages/HigherLower';
import Highlighter from './pages/Highlighter';
import Home from './pages/Home';
import Hundred from './pages/100px';
import Idle from './pages/Idle';
import Intsgraram from './pages/Intsgraram';
import Keyboard from './pages/Keyboard';
import Life from './pages/Life';
import Login from './pages/Login';
import Lorem from './pages/Lorem';
import LowerHigher from './pages/LowerHigher';
import Madlibs from './pages/Madlibs';
import Memory from './pages/Memory';
import Mind from './pages/Mind';
import Noun from './pages/Noun';
import Numbers from './pages/Numbers';
import PetCat from './pages/PetCat';
import Pixel from './pages/Pixel';
import Privacy from './pages/Privacy';
import Rock from './pages/Rock';
import Scrabble from './pages/Scrabble';
import Secret from './pages/Secret';
import Shortener from './pages/Shortener';
import Shorteners from './pages/Shorteners';
import Smell from './pages/Smell';
import Spotify from './pages/Spotify';
import Taskmaster from './pages/Taskmaster';
import Test from './pages/Test';
import TicTacToe from './pages/TicTacToe';
import Typist from './pages/Typist';
import Wrestler from './pages/Wrestler';
import Words from './pages/Words';
import WordJumble from './pages/WordJumble';
import WordScramble from './pages/WordScramble';


function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <div className="container-mg">

          <Navbar />
          <Routes>
            <Route path="/ai" element={<AI />} />
            <Route path="/thesaurus" element={<Thesaurus />} />
            <Route path="/animation" element={<Animation />} />
            <Route path="/animation2" element={<Animation2 />} />
            <Route path="/apis" element={<APIs />} />
            <Route path="/auto" element={<Auto />} />
            <Route path="/baseball" element={<Baseball />} />
            <Route path="/bio" element={<Bio />} />
            <Route path="/button" element={<Button />} />
            <Route path="/camera" element={<Camera />} />
            <Route path="/cheese-cat" element={<Hangman />} />
            <Route path="/choice" element={<Choice />} />
            <Route path="/choose" element={<Choose />} />
            <Route path="/chess-clock" element={<Clock />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/cow" element={<Cow />} />
            <Route path="/definitions" element={<Definitions />} />
            <Route path="/dice" element={<Dice />} />
            <Route path="/dog" element={<Dog />} />
            <Route path="/dogs-and-cats" element={<DogsAndCats />} />
            <Route path="/dogs" element={<Dogs />} />
            <Route path="/draw" element={<Draw />} />
            <Route path="/facts" element={<Facts />} />
            <Route path="/find" element={<Find />} />
            <Route path="/food" element={<Food />} />
            <Route path="/forever" element={<Forever />} />
            {/* <Route path="/grudo" element={<Grudo user={user} />} /> */}
            <Route path="/grucha-libre" element={<Battlers />} />
            {/* <Route path="/hands" element={<Hands user={user} />} /> */}
            <Route path="/hangman" element={<Hangman />} />
            <Route path="/hex" element={<Hex />} />
            <Route path="/higher-lower" element={<HigherLower />} />
            <Route path="/highlighter" element={<Highlighter />} />
            <Route path="/hundred" element={<Hundred />} />
            <Route path="/idle" element={<Idle />} />
            <Route path="/intsgraram" element={<Intsgraram />} />
            <Route path="/keyboard" element={<Keyboard />} />
            <Route path="/life" element={<Life />} />
            <Route path="/login" element={<Login />} />
            <Route path="/lorem" element={<Lorem />} />
            <Route path="/lower-or-higher" element={<LowerHigher />} />
            <Route path="/madlibs" element={<Madlibs />} />
            <Route path="/mind" element={<Mind />} />
            <Route path="/memory" element={<Memory />} />
            <Route path="/noun" element={<Noun />} />
            <Route path="/numbers" element={<Numbers />} />
            <Route path="/pet" element={<PetCat />} />
            <Route path="/pixel" element={<Pixel />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/rock" element={<Rock />} />
            <Route path="/scrabble" element={<Scrabble />} />
            <Route path="/secret" element={<Secret />} />
            <Route path="/shortener" element={<Shortener />} />
            <Route path="/shorteners" element={<Shorteners />} />
            <Route path="/smell" element={<Smell />} />
            <Route path="/spotify" element={<Spotify />} />
            <Route path="/taskmaster" element={<Taskmaster />} />
            <Route path="/test" element={<Test />} />
            <Route path="/tic-tac-toe" element={<TicTacToe />} />
            <Route path="/typist" element={<Typist />} />
            <Route path="/url" element={<Shorteners />} />
            <Route path="/url/:slug" element={<Shortener />} />
            <Route path="/word-jumble" element={<WordJumble />} />
            <Route path="/word-scramble" element={<WordScramble />} />
            <Route path="/words" element={<Words />} />
            <Route path="/wrestler" element={<Wrestler />} />
            <Route path="/" exact element={<Home />} />
          </Routes>

        </div>
{/* 
        <footer>
          <img src={require('./img/ad.png')} alt="Generic ad banner" />
          </footer> */}
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
