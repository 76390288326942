import React, { useState, useEffect} from 'react';

import axios from 'axios'
import { useCustomContext } from '../state/context';
import Info from '../components/Info';
function App() {

  const { updateScore } = useCustomContext();
  const [reload, setReload] = useState(false);
  const [letters, setLetters] = useState([]);

  const lettersLength = 400;

  function getRandomLetter() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return letters[Math.floor(Math.random() * letters.length)];
  }

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < lettersLength; i++) {
      temp.push(getRandomLetter());
    }
    setLetters(temp);
  }, [reload])

  return (
    <main>
     <Info page="bio" />
      <div className="choose">
      {
        letters.map((item, index) => {
          return(
            <span key={index}>
              {item}
            </span>
          )
        })
      }
      </div>
      </main>
  );
}

export default App;
